<template>
    <!-- 我的客户 -->
    <div class="customer_box">
        <div style="margin-bottom:20px;">
            <div class="customer_search_box" style="position: static;">
                <div class="search-item">
                    <span>关键字</span>
                    <el-input style="width: 150px;" clearable placeholder="单据编号/客户名称/申请人" size="small" v-model="filterinfo.search"></el-input>
                </div>
                <div class="search-item">
                    <span>状态</span>
                    <el-select style="width: 140px;" clearable @clear="filterinfo.status=0" size="small" v-model="filterinfo.status" placeholder="请选择">
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-item">
                    <span>创建日期</span>
                    <el-date-picker style="width: 180px;" v-model="timeRange" type="daterange" size="small" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="btn_box" >
                    <el-button class="btn_item" type="primary" size="small" @click="getRefundList(1)">查询</el-button>
                    <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
                    <el-button class="btn_item" type="primary" size="small" @click="doRefund">发起退货</el-button>
                    <el-button class="btn_item" size="small" @click="downLoadAll">下载商品列表</el-button>
                </div>
            </div>
        </div>
        <div class="table_mode">
            <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
                <el-table-column prop="onlineReturnNumber" label="单据编号" min-width="200" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="companyName" label="客户名称" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="outMoney" label="发货金额" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="ReturnMoney" label="退货金额" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="statusTitle" label="状态" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <!-- <el-table-column prop="amount" label="本月下单金额" sortable="custom" min-width="130" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span style="color: #F92B2B;">￥{{Number(scope.row.amount).toFixed(2)}}</span>
            </template>
          </el-table-column> -->
                <el-table-column prop="createPerson" label="创建人" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column label="操作 " width="150" align="center">
                    <template slot-scope="scope">
                        <div class="table_btn">
                            <el-popconfirm title="确定撤销吗？" @onConfirm="reback(scope.row.id,1)" v-if="scope.row.canRevoke">
                                <div class="btn_item" slot="reference" v-if="scope.row.canRevoke">撤销</div>
                            </el-popconfirm>
                            <el-popconfirm title="确定删除吗？" @onConfirm="reback(scope.row.id,2)" v-if="scope.row.canDelete">
                                <div class="btn_item" slot="reference" v-if="scope.row.canDelete">删除</div>
                            </el-popconfirm>
                            <div class="btn_item" v-if="scope.row.canImprove" @click="overTicket(scope.row.id)">完善单据</div>
                            <div class="btn_item" @click="viewClick(scope.row.id)">查看</div>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </div>
        <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getRefundList" @size-change="getRefundList(1)">
            </el-pagination>
        </div>
        <!-- <el-dialog :visible="addressVisible" :lock-scroll="false" center custom-class="self-dialog" @close="onClose">
            <span class="dialog-body">
                <div>
                    <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">完善客户信息</p>
                    <p style="font-weight: 500;font-size: 12px;color: #F92B2B;margin:10px 0;">请先完善客户的信息后再进行代客下单操作</p>
                </div>
                <div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                        <el-form-item label="联系人" prop="contacter">
                            <el-input v-model="ruleForm.contacter" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="mobile">
                            <el-input v-model="ruleForm.mobile" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="企业地址" prop="provinceId">
                            <el-select style="margin-right:10px;" v-model="ruleForm.provinceId" @change="provinceChange" placeholder="请选择省">
                                <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>

                        </el-form-item>
                        <el-form-item prop="cityId">
                            <el-select style="margin-right:10px;" v-model="ruleForm.cityId" @change="cityChange" placeholder="请选择市">
                                <el-option v-for="item in cityOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>

                        </el-form-item>
                        <el-form-item prop="areaId">
                            <el-select v-model="ruleForm.areaId" placeholder="请选择区">
                                <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>

                        </el-form-item>

                        <el-form-item label="详细地址" prop="address">
                            <el-input type="textarea" placeholder="请输入" v-model="ruleForm.address"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button style="width:200px;" @click="addressVisible=false">取消</el-button>
                <el-button style="background:#1A9DFF;color:#fff;width:200px;" @click="submitForm">确定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>
  
  <script>
// import { handlerTableData } from '@/utils/common'
import { handlerTableData, downloadExcelFile } from '@/utils/common'
export default {
    name: 'MyCustomer',
    data() {
        return {
            loading: false,
            total: 0,
            provinceOptions: [],
            cityOptions: [],
            areaOptions: [],
            filterinfo: {
                page: 1,
                pageSize: 10,
                search: '',
                status: 0
            },
            timeRange: '',
            tableData: [],
            attributeOptions: [],
            stateOptions: [
                {
                    label: '全部',
                    value: 0
                },
                {
                    label: '完成',
                    value: 1
                },
                {
                    label: '省区审核',
                    value: 2
                },
                {
                    label: '大区审核',
                    value: 3
                },
                {
                    label: '业务员完善单据',
                    value: 4
                },
                {
                    label: '仓库收货入库',
                    value: 5
                },

            ],
            options: [],
            roleNumber: localStorage.getItem('roleNumber'),
            addressVisible: false,
            ruleForm: {
                contacter: '',
                mobile: '',
                provinceId: '',
                cityId: '',
                areaId: '',
                memberId: '',

            },
            rules: {
                contacter: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                    { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
                ],
                provinceId: [
                    { required: true, message: '请选择省', trigger: 'blur' }

                ],
                cityId: [
                    { required: true, message: '请选择市', trigger: 'blur' }

                ],
                areaId: [
                    { required: true, message: '请选择区', trigger: 'blur' }

                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }

                ],

            }
        };
    },
    created() {

    },
    mounted() {
        this.getRefundList()
        this.getAddress(0, 'provinceOptions')
        console.log(this.roleNumber);
        if (this.roleNumber == 3) {
            this.dkxdClick(0, 3)
        }
    },
    methods: {
        async downLoadAll() {
            const res = await this.$api.exportAll()
            if (res.status == 200) {
                downloadExcelFile(res)
            }
        },
        cityChange() {
            this.getAddress(this.ruleForm.cityId, 'areaOptions')
            this.ruleForm.areaId = ''
            this.areaOptions = []
        },
        //添加地址

        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.subCustomAddress(this.ruleForm)
                    if (res.data.code == 200) {
                        this.addressVisible = false
                        this.$message.success('添加成功')
                        this.getRefundList()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //清空数据
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
        //关闭弹窗
        onClose() {
            this.addressVisible = false
            this.resetForm()
        },
        handleChange() {

        },
        //获取全国地址
        async getAddress(parentId, option) {
            const res = await this.$api.getAllAddress({ parentId })
            // if(res.data.code!=200) return this.$message.error(res.data.msg)
            this[option] = res.data.data
        },
        // 点击重置
        resetClick() {
            this.timeRange = []
            this.filterinfo = {
                page: 1,
                pageSize: 10,
                search: '',
                status: 0
            }
            this.getRefundList()
        },
        // 获取我的客户列表
        getRefundList(num) {
            if (num == 1) {
                this.filterinfo.page = 1
            }
            let params = this.filterinfo
            if (this.timeRange && this.timeRange.length) {
                params.timeRange = this.timeRange[0] + '/' + this.timeRange[1]
            } else {
                params.timeRange = ''
            }
            this.loading = true
            this.$api.refundList(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = handlerTableData(res.data.data.onlineReturnLists)
                    // this.tableData = res.data.data.list
                    // this.options = res.data.data.shopType
                    // this.attributeOptions = res.data.data.attr
                    this.total = res.data.data.total
                }
            });
        },
        async reback(id, doType) {
            const res = await this.$api.reback({ id, doType })
            if (res.data.code == 200) {
                if (doType == 1) {
                    this.$message.success('撤销成功')
                } else {
                    this.$message.success('删除成功')
                }
                this.getRefundList()
            }
        },
        doRefund() {
            this.$router.push({ name: 'newRefund' })
        },
        overTicket(id) {
            this.$router.push('/refundDetail?id=' + id)
        },
        viewClick(id) {
            this.$router.push('/refundDetail?type=view&id=' + id)
        }
    }
};
  </script>
  <style lang="scss" scoped>
.customer_box {
    position: relative;
}
.el-form-item__label {
    text-align: left !important;
}
</style>
  <style lang="scss" >
.self-dialog {
    width: 500px;
    border-radius: 10px !important;
    .el-form-item__content {
        display: flex !important;
    }
}
</style>