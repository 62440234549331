<template>
    <div class="detailPage">
        <Bread :direct="direct" :bgc="bgc"></Bread>
        <div style="height:100%;">
            <div class="sc_middle type_area">
                <div class="leftPic">
                    <div class="goodsPic" style="position: relative;">
                        <div class="previewPic" @mouseenter="doshow()" @mouseleave="markFlag = false" @mousemove="domove($event)" ref="previewPic">
                            <img ref="previewImg" style="width: 378px; height: 378px" :src="bigImg" alt="" />
                            <img src="../../assets/images/goodsDetail/no-goods.svg" alt="" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)" v-if="goods.stock==0">
                            <!-- <i class="act_tag" v-if="+goods.full"></i>
                            <i class="special_tag" v-if="goods.type===2"></i>
                            <i class="promotion_tag" v-if="goods.type===5"></i> -->
                            <div ref="mark" class="mark" v-if="markFlag"></div>
                            <div ref="largePic" class="largePic" v-if="markFlag"></div>
                            <img v-if="goods.isRecent" style="width: 98px;height: 98px;position: absolute;top: 0;left: 0;" src="../../assets/images/order/recentBuy.png" alt="">
                        </div>
                        <div class="scrollPic">
                            <div class="prev" @click.prevent="doprev()" :class="{'no-move':activeSelect===0}">
                                <img src="../../assets/images/goodsDetail/right.png" alt="">
                            </div>
                            <div class="next" @click.prevent="donext()" :class="{'no-move':activeSelect===goods.productGoodsImgs.length-1}">
                                <img src="../../assets/images/goodsDetail/left.png" alt="">
                            </div>
                            <div class="items">
                                <ul ref="moveul">
                                    <li v-for="(item, index) in goods.productGoodsImgs" :key="index" style="cursor: pointer;" :class="{ liselected: activeSelect === index }" @click="selectImg(item, index)">
                                        <i :class="{ iselected: activeSelect === index }"></i>
                                        <img :src="item.thumbPath" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="tooltip">
                        温馨提示：<span style="color: #F34646">图片仅供参考，请以实物为准。</span>
                    </div>
                </div>
                <div class="rightDesc">
                    <div class="goodsname">
                        <ul>
                            <li style="display:flex;">{{goods.Name}}
                                <!-- <div class="tag">
                            <span style="margin-left:10px;" v-if="goods.isRecent"  :class="{yh:1}" >已合作产品</span>
                        </div> -->
                            </li>
                        </ul>

                        <div class="product-money">
                            <div class="product-money-left">
                                <div class="gongyin">
                                    <span class="discount">价格</span>
                                    <span class="count">
                                        <i class="discount-m" style="font-weight:500">￥</i>
                                        <i class="count-m" v-html="getPriceWithDifferentFontSize(goods.jiage)"></i>
                                    </span>
                                </div>
                                <div v-if="goods.jiage!=goods.price" class="lingshou" style="text-decoration-line: line-through;color: #999999;font-size: 16px;">
                                    <span style="font-size: 16px;color: #999999;">原价</span>
                                    <span style="display: flex;font-size: 16px;color: #999999;">
                                        <i>￥</i>
                                        <i>{{goods.price}}</i>
                                    </span>
                                </div>
                                <div class="lingshou">
                                    <span style="font-size: 16px; color: #333333">建议零售价</span>
                                    <span style="display: flex">
                                        <i class="sale-mark">￥</i>
                                        <i class="sale-m">{{goods.retailPrice}}</i>
                                    </span>
                                </div>

                                <div class="lingshou">
                                    <span style="font-size: 16px; color: #333333">毛利率</span>
                                    <span style="display: flex">
                                        <i class="sale-mark">￥</i>
                                        <i class="sale-m">{{goods.grossMargin}}</i>
                                    </span>
                                </div>
                                <div v-if="goods.coupon_str" class="coupon-later">
                                    <span>{{goods.coupon_str}}</span>
                                </div>
                                <!-- <div class="cutline"></div>
                                <div style="font-size: 16px;color: #999999;margin-left:20px;text-align:center;">
                                    <div style="font-size: 14px;">销量</div>
                                    <div>120w</div>
                                </div> -->
                            </div>

                        </div>
                        <div v-if="goods.acts.length" ref="salesbox" class="salesbox" @mouseenter="saleEnter" @mouseleave="saleLeave" :class="{'one-salebox':goods.acts&&goods.acts.length===1,'red':goods.acts&&goods.acts.length===1&&(goods.acts[0].actType===2||goods.acts[0].actType===8),'blue':goods.acts&&goods.acts.length===1&&goods.acts[0].actType===9}">
                            <b v-if="goods.acts&&goods.acts.length>1&&on"><img src="../../assets/images/goodsDetail/allow-bottom.svg" alt=""></b>
                            <b v-if="goods.acts&&goods.acts.length>1&&!on"><img src="../../assets/images/goodsDetail/up-allow.svg" alt=""></b>
                            <ul class="inbox" :class="{'over-inbox':goods.acts.length!==1}" ref="inbox" v-if="goods.acts&&goods.acts.length>1">
                                <li v-for="(item,index) in goods.acts" :key="index" :class="{'float':!on}">
                                    <img v-if="item.actType===4" src="../../assets/images/goodsDetail/cuxiao-buy.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===8" src="../../assets/images/goodsDetail/reward.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===7" src="../../assets/images/goodsDetail/cuxiao-all.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===99" src="../../assets/images/goodsDetail/yijia.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===2" src="../../assets/images/goodsDetail/yhtejia.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===9" src="../../assets/images/goodsDetail/lidai.svg" alt="" style="vertical-align: middle;">
                                    <!-- <span v-if="on" style="font-size: 14px;color: #BE8411;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span> -->
                                    <span v-if="item.actType!==2&&item.actType!==8&&item.actType!==99&&on" style="font-size: 14px;color: #BE8411;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                    <span v-if="(item.actType===2||item.actType===8)&&on" style="font-size: 14px;color: #F92B2B;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                    <span v-if="item.actType===99&&on" style="font-size: 14px;color: #1A9DFF;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                </li>
                            </ul>
                            <ul v-else class="inbox" :class="{'reward':(goods.acts[0].actType===2||goods.acts[0].actType===8),'yijia':(goods.acts[0].actType===9)}">
                                <li v-for="(item,index) in goods.acts" :key="index">
                                    <img v-if="item.actType===4" src="../../assets/images/goodsDetail/cuxiao-buy.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===8" src="../../assets/images/goodsDetail/reward.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===7" src="../../assets/images/goodsDetail/cuxiao-all.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===99" src="../../assets/images/goodsDetail/yijia.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===2" src="../../assets/images/goodsDetail/yhtejia.svg" alt="" style="vertical-align: middle;">
                                    <img v-if="item.actType===9" src="../../assets/images/goodsDetail/lidai.svg" alt="" style="vertical-align: middle;">
                                    <span v-if="item.actType!==2&&item.actType!==8&&item.actType!==99" style="font-size: 14px;color: #BE8411;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                    <span v-if="item.actType===2||item.actType===8" style="font-size: 14px;color: #F92B2B;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                    <span v-if="item.actType===99" style="font-size: 14px;color: #1A9DFF;vertical-align: middle;font-weight: 400;">
                                        {{item.description}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="sc_content" ref="sc_content">
                            <div class="sc_content_top">
                                <div class="sc_content_detail" style="width:100%;">
                                    <div style="display:flex;justify-content: space-between;">
                                        <!-- -->
                                        <div style="width:400px;">
                                            <span style="margin-right: 10px;color: #999999; font-size: 16px">首营资料:</span>
                                            <el-button style="height:30px;line-height:5px;padding:8px 5px;" type="primary" :loading="isLoading" @click="licenseDownLoad">首营下载</el-button>

                                        </div>
                                        <div style="display: flex;width:350px;">
                                            <span class="first">生产厂家:</span>
                                            <span class="second">{{goods.produceUnit}}</span>
                                            <img style="width:25px;height:25px;cursor: pointer;" @click="copyText(goods.produceUnit)" src="../../assets/images/goodsDetail/copy.png" alt="">
                                        </div>
                                        <!-- <div>
                                            <span style="margin-right: 10px;color: #999999; font-size: 16px">包装</span>
                                            <span>{{goods.unit}}</span>
                                        </div> -->
                                    </div>
                                    <div style="display:flex;justify-content: space-between;">
                                        <!-- <div>
                                            <span style="margin-right: 10px;color: #999999; font-size: 16px">库存</span>
                                            <span>{{goods.stock}}</span>
                                        </div> -->
                                        <div style="width:400px;">
                                            <span style="margin-right: 10px;color: #999999; font-size: 16px">规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格:</span>
                                            <span>{{goods.norms}}</span>
                                        </div>
                                        <div style="display:flex;width:350px;">
                                            <span class="first" style="color: #999999; font-size: 16px">批准文号:</span>
                                            <span class="second" style="color: #333333; font-size: 16px;">{{goods.license}}</span>
                                            <img style="width:25px;height:25px;cursor: pointer;" @click="copyText(goods.license)" src="../../assets/images/goodsDetail/copy.png" alt="">
                                        </div>
                                    </div>
                                    <div style="display:flex;justify-content: space-between;">
                                        <div class="mid_first" style="width:400px;">
                                            <span style="color: #999999; margin-right: 10px;font-size: 16px">生产日期:</span>
                                            <span style="color: #333333; font-size: 16px; margin-top: -8px">{{goods.produceDate}}</span>
                                        </div>
                                        <div class="mid_first" style="width:350px;">
                                            <span style="color: #999999; margin-right: 10px;font-size: 16px">有效期至:</span>
                                            <span style="color: #333333; font-size: 16px; margin-top: -8px">优于{{goods.lastDate}}</span>
                                        </div>
                                    </div>
                                    <div style="display:flex;justify-content: space-between;">
                                        <div class="mid_first" style="display:flex;width:400px;">
                                            <span style="color: #999999;margin-right: 10px; font-size: 16px">医保信息:</span>
                                            <el-tooltip popper-class="handlerTab_tooltip" effect="light" placement="bottom-start">
                                                <div slot="content">
                                                    <table class="tableClass" style="width:500px;">
                                                        <tr>
                                                            <th>医保类型</th>
                                                            <th>医保编码</th>
                                                        </tr>
                                                        <tr>
                                                            <td>{{goods.medicalInsuranceType?goods.medicalInsuranceType:'暂无'}}</td>
                                                            <td>{{goods.medicalInsuranceCode?goods.medicalInsuranceCode:'暂无'}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <el-link style="font-size: 16px" type="primary" :underline="false">查看详情</el-link>
                                            </el-tooltip>

                                        </div>
                                        <div class="mid_first" style="width:350px;">
                                            <span style="color: #999999;margin-right: 10px; font-size: 16px">条&nbsp;&nbsp;形&nbsp;码:</span>
                                            <span style="color: #333333; font-size: 16px; margin-top: -8px">{{goods.barCode}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="sc_content_mid" >
                                <div class="mid_title">
                                 
                                </div>
                                <div class="mid_content">
                                  
                                </div>
                            </div> -->
                        </div>
                        <div style="margin-top:20px;">
                            <el-radio-group v-model="packSelect" size="small" @input="packSelectChange">
                                <el-radio style="position: relative;overflow: hidden;" label="1" border>中包装/{{packAge}}
                                    <div v-if="packSelect=='1'" class="box-con">
                                    <img src="../../assets/images/goodsDetail/selectD.svg" alt="">
                                </div>
                                </el-radio>
                                <el-radio style="position: relative;overflow: hidden;" label="2" border>件装/{{goods.jlgg}}
                                    <div v-if="packSelect=='2'" class="box-con">
                                        <img src="../../assets/images/goodsDetail/selectD.svg" alt="">
                                    </div>
                                </el-radio>
                            </el-radio-group>

                        </div>
                        <div class="shopping">
                            <div class="computed">
                                <el-input-number v-model="goods.buyNumber" controls-position="right" :step="+countStep" style="width:100px;height:46px;" :min="+countStep"></el-input-number>
                                <span v-if="goods.startingPurchase" class="xiangou">起购{{goods.startingPurchase}}</span>
                            </div>
                            <div class="addcar">
                                <a href="#" v-if="goods.cartNumber>0&&!$route.query.prepareOrderNumber" @click.prevent="addShopCar()" class="exist" :class="{disabled:goods.stock == 0,addcar_btn:goods.stock != 0}">
                                    <img style="width: 21px; height: 20px; margin-right: 5px" src="../../assets/images/goodsDetail/scar.svg" alt="" />
                                    已在采购车
                                </a>
                                <a href="#" v-else @click.prevent="addShopCar()" class="" :class="{disabled:goods.stock == 0,addcar_btn:goods.stock != 0}">
                                    <img v-if="$route.query.prepareOrderNumber" style="width: 21px; height: 20px; margin-right: 5px" src="@/assets/images/order/order.svg" alt="">
                                    <img v-else style="width: 21px; height: 20px; margin-right: 5px" src="../../assets/images/goodsDetail/scar.svg" alt="" />
                                    {{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}
                                </a>
                            </div>
                            <cartDialog v-if="cartList.length" @realAddCar="realAddCar" @closeDialog="closeDialog" :countStep="+countStep" :cartList="cartList" :farenVisible="farenVisible"></cartDialog>
                            <!-- <div class="buycar">
                                <a href="#" style="height:46px !important;  box-sizing: border-box;" @click.prevent="goPay"> 立即购买 </a>
                            </div> -->
                            <a class="collect" @click.prevent="collect(goods.goodsId)">
                                <div class="line"></div>
                                <img ref="celloctImg" v-if="goods.favorite" style="width: 16px; height: 16px ; margin-right: 5px" src="../../assets/images/goodsDetail/collect.png" alt="" />
                                <img ref="celloctImg" v-else style="width: 16px; height: 16px ; margin-right: 5px" src="../../assets/images/goodsDetail/cancel-collect.png" alt="" />
                                <span style="font-size: 18px; color: #666">{{goods.favorite?'已收藏':'收藏'}}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" type_area">
                <div class="sc_foot">
                    <div class="recomend">
                        <div class="recgoods">推荐商品</div>
                        <template v-if="recomendList.length">
                            <div ref="recomendPic" class="recomendPic" v-for="(item,index) in recomendList" :key="index">
                                <div @click.prevent="goOthers(item.goodsId)" style="text-decoration: none; cursor: pointer; color: inherit">
                                    <div class="recImg">
                                        <img :src="item.thumbUrl" alt="" />
                                        <div class="collectImg" @click.stop="recCollect(item.goodsId,item)">
                                            <img v-if="item.favorite" src="../../assets/images/goodsDetail/collect.png" alt="">
                                            <img v-else src="../../assets/images/goodsDetail/recCollect.svg" alt="">
                                        </div>

                                        <div style="position: absolute;bottom: 0;display:flex;">
                                            <div class="spe-price" v-if="item.jiage!=item.price">特价</div>
                                            <!--  <div class="own">自有</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div style="padding:0 0 0 10px;font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 16px;color: #333333;">{{item.productName}}</div>
                                <div style="display:flex;padding-left:10px;margin-top:5px;">
                                    <div class="title-box">规</div>
                                    <div style="margin-left:10px;overflow:hidden;white-space:nowrap;width:180px;text-overflow:ellipsis;"> <el-tooltip class="item" effect="dark" :disabled="item.norms.length<23" :content="item.norms" placement="top-start">
                                            <span style="color: #333333;">{{item.norms}}</span>
                                        </el-tooltip></div>

                                </div>
                                <div style="display:flex;padding-left:10px;margin-top:5px;">
                                    <div class="title-box">厂</div>
                                    <div style="margin-left:10px;">{{item.produceUnit}}</div>
                                </div>
                                <div style="display:flex;padding-left:10px;margin-top:5px;">
                                    <div class="title-box">效</div>
                                    <div style="margin-left:10px;"> 优于{{item.lastDate}}</div>
                                </div>
                                <div style="display:flex;align-items:center;padding-left:10px;margin:10px 0 0px;">
                                    <div style="font-weight: bold;font-size: 16px;color: #F34141;"><span style="font-size:12px;">￥</span>{{item.jiage}}</div>
                                    <div v-if="item.jiage!=item.price" style="font-size: 10px;color: #ACACAC;text-decoration-line: line-through;margin-left:5px;">原价{{item.price}}</div>
                                </div>
                                <div class="car-box">
                                    <div>毛利率 {{item.grossMargin}}</div>
                                    <div class="car-line"></div>
                                    <div>零售价 ￥{{item.retailPrice}}</div>
                                </div>

                                <!-- <div ref="store" class="store">
                                    <p>批 号：以实物为准</p>
                                    <p>库 存：{{item.quota-item.already>100?'&#62; 100':'&#60; 100' }}</p>
                                </div> -->
                            </div>
                        </template>
                        <div v-else class="defaultRecomend">
                            <img src="http://mall.huida.cc/picture/home/pro_null.png" alt="" />
                            <p>抱歉，暂无相关推荐商品，我们会尽快完善，敬请期待！</p>
                        </div>
                    </div>
                    <div class="goodsdetail">
                        <div class="detailList">
                            <ul>
                                <li v-for="(item, index) in selectLiItem" :key="index" :class="{
                selectLi: activeLi === index,
                defaultBorder: activeLi === 0,
              }" @click="activeLi = index">
                                    {{ item }}
                                    <!-- <i :class="{ i1: activeLi === index }"></i>
                                    <i :class="{ i2: activeLi === index }"></i> -->
                                </li>
                            </ul>
                        </div>
                        <div class="detailContent">
                            <ul>
                                <!-- <li v-show="activeLi === 1" v-html="goods.introduc"></li> -->
                                <li v-show="activeLi === 3">
                                    <div class="search">
                                        按批号搜索
                                        <input type="text" v-model="batch_code" />
                                        <a class="a1" href="#" @click.prevent="goodsDownload">下载商品证件</a>
                                        <a class="a2" href="#" @click.prevent="codeSearch">搜索</a>
                                    </div>
                                </li>
                                <li v-show="activeLi === 0">
                                    <img v-if="goods.DetailImg" :src="goods.DetailImg" alt="">
                                    <div v-else class="nodata_box">
                                        <img style="width:30%" src="../../assets/images/index/nodata.png" alt="">
                                        <span>暂无数据哦~</span>
                                    </div>
                                </li>
                                <li v-show="activeLi === 1">
                                    <div style="
                  width: 710px;
                  height: 430px;
                  align: center;
                  margin-top: 30px;
                  margin-left: 70px;
                  float: left;
                ">
                                        <div style="margin-bottom: 26px; margin-left: 42px;">
                                            <div style="font-size: 14px;color: #333333;margin-bottom:8px;">
                                                电话
                                            </div>
                                            <div>
                                                <el-input v-model="tel" placeholder="请输入您的电话号码，方便我们和您取得联系"></el-input>
                                            </div>
                                        </div>
                                        <div style="margin-left: 42px; margin-bottom: 22px;">
                                            <div style="color: #333333;font-size: 14px;margin-bottom:8px;">
                                                内容
                                            </div>
                                            <div>
                                                <el-input type="textarea" placeholder="请留下您的宝贵意见，我们客服人员会联系您，接洽您对此商品的相关宜事！" v-model="content" maxlength="150" show-word-limit :autosize="{ minRows: 10 }">
                                                </el-input>
                                            </div>
                                        </div>

                                        <div style="margin-top: 4px;margin-left:42px;">
                                            <el-button @click="writeMessage" type="primary" style="width:100%;border-radius:0;">提交</el-button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog class="tipDialog" :visible.sync="priceVisible" width="20%" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" @close="priceClose" :show-close="false">
            <span style="font-size: 16px;color: #333333;">由于公司对产品价格调整，已取消您
                对此品种的议价价格!</span>
            <div style="font-size: 14px;color: #999;margin-top: 15px;">如需购买此品种，请选择下面的处理方案:</div>
            <!-- <div class="priceBtn" :class="{'selectBtn':selectBtn == 0}" @click="selectBtn = 0">我要以现在的供货价购买</div> -->

            <div v-for="(item,index) in noticeList" :key="index">
                <div v-if="index==0"  class="priceBtn" :class="{'selectBtn':selectBtn == item.id}" @click="selectBtn = item.id">
                    <div>
                        {{ item.title }}
                        <div v-if="item.remark" class="contact" style="font-size: 10px;color: #BDBDBD;">{{item.remark}}</div>
                    </div>

                </div>
                <div v-if="index==1"  class="priceBtn" style="line-height: 20px;" :class="{'selectBtn':selectBtn == item.id}" @click="selectBtn = item.id">
                    <div>
                        {{ item.title }}
                        <div v-if="item.remark" class="contact" style="font-size: 10px;color: #BDBDBD;">{{item.remark}}</div>
                    </div>

                </div>
            </div>
            <div v-if="warnNotice" style="font-size: 12px;margin-top: 8px;color: #F92B2B;">
                {{ warnNotice }}
            </div>
            <div @click="surePrice(selectBtn)" style="font-weight: bold;margin-top: 22px;padding: 12px 0 0;font-size: 16px;color: #2554E5;text-align: center;border-top: 1px solid #E0E0E0;cursor: pointer;">确定</div>

        </el-dialog>
        <!-- <priceDialog v-if="tipVisible" @tipClose="tipClose" @noBuy="noBuy" :priceList="priceList"  :tipVisible="tipVisible"></priceDialog> -->
    </div>
</template>

<script>
import Bread from '@/components/Bread'
import cartDialog from './component/cartDialog.vue'
// import priceDialog from '@/views/shopCar/component/priceDialog.vue'
import { downloadFile } from '@/utils/common'
import { getCarNum, getOrderNum } from '@/utils/carNumber'
export default {
    components: {
        Bread,
        cartDialog,

    },
    data() {
        return {
            direct: [],
            noticeList: [],
            bgc: '#fff',
            count: 1,
            markFlag: false,
            priceVisible: false,
            activeSelect: 0,
            selectBtn: null,
            warnNotice: null,
            activeLi: 0,
            textarea: '',
            input: '',
            selectLiItem: ["商品详情", "商品留言"],
            goods: {},
            image_list: [],
            recomendList: [],
            batch_code: '',
            cartList: [],//
            tel: '',
            content: '',
            farenVisible: false,
            // tipVisible: false,
            carlist: [
            ],
            // priceList: [],
            on: false,
            bigImg: '',
            loadingInstance: null,
            packSelect: '1',
            packAge: '',
            isLoading: false
        };
    },
    created() {
        // this.priceVisible = true
        this.getDetailList()

        this.getRecommendList()
        scrollTo(0, 0)

    },
    computed: {
        countStep: {
            get() {
                if (this.goods.pack.length) {
                    const index = this.goods.pack.findIndex((item) => {
                        return item.isPack == 'on'
                    })
                    if (index !== -1) {
                        return this.goods.pack[index].num < this.goods.startingPurchase ? this.goods.startingPurchase : this.goods.pack[index].num
                    } else {
                        return this.goods.pack[0].num < this.goods.startingPurchase ? this.goods.startingPurchase : this.goods.pack[0].num
                    }
                } else {
                    return this.goods.startingPurchase
                }
            },
            set(value) {// set方法，修改计算属性的值，value为新的值
                console.log(value);
                if (this.goods.pack.length) {
                    const index = this.goods.pack.findIndex((item) => {
                        return item.isPack == 'on'
                    })
                    if (index !== -1) {
                        this.goods.pack[index].num = value
                    } else {
                        this.goods.pack[0].num = value
                    }
                } else {
                    this.goods.startingPurchase = value
                }
            }
        }
    },
    methods: {
        packSelectChange(e) {
            if (e == 1) {
                this.goods.buyNumber = this.packAge
                this.countStep = this.packAge
            } else {
                this.goods.buyNumber = this.goods.jlgg
                this.countStep = this.goods.jlgg
            }
        },
        // tipClose() {
        //     this.tipVisible = false
        // },
        // noBuy() {
        //     this.tipVisible = false
        // },
        priceClose() {
            this.priceVisible = false
        },
        async surePrice(optionId) {
            if (!optionId) return this.$message.error('请选择')
            const res = await this.$orderApis.selectCheck({
                goodsId: this.goods.goodsId,
                optionId
            })
            console.log(res);

            if (res.data.code !== 200) {
                this.warnNotice = res.data.msg
            } else {
                this.priceVisible = false
                this.getDetailList()
            }
            // this.priceVisible = false
        },
        async goodsChecked() {
            const res = await this.$orderApis.goodsCheck({ id: +this.$route.query.goodsId })
            if (res.data.code == 200 && res.data.data.needCheck) {
                this.noticeList = res.data.data.lists
                this.priceVisible = true
            }
        },
        async licenseDownLoad() {
            this.isLoading = true
            const res = await this.$orderApis.downloadLicense({ goodsId: +this.$route.query.goodsId })
            console.log(res, '123');
            //    if(res.data.code==100001) return this.$message.error(res.msg)
            if (res.status == 200) {
                this.isLoading = false
                downloadFile(res)
            }

        },
        getPriceWithDifferentFontSize(price) {
            var priceParts = price.toFixed(2).toString().split('.'); // 拆分价格为整数部分和小数部分
            var integerPart = priceParts[0];
            var decimalPart = priceParts[1];

            var priceHTML = '<span style="font-size:26px;">' + integerPart + '.</span><span style="font-size:22px;">' + decimalPart + '</span>';

            return priceHTML;
        },
        saleEnter() {
            this.on = true
            if (this.goods.acts && this.goods.acts.length > 1) {
                console.log(this.$refs.sc_content);
                this.$refs.sc_content.style.marginTop = '44px';
                this.$refs.salesbox.style.position = 'absolute'
                this.$refs.salesbox.style.height = 'auto'
                this.$refs.inbox.style.overflow = 'visible'
                this.$refs.inbox.style.height = '100%'
            }
        },
        saleLeave() {
            this.on = false
            if (this.goods.acts && this.goods.acts.length > 1) {
                this.$refs.sc_content.style.marginTop = '0px';
                this.$refs.salesbox.style.position = 'relative'
                this.$refs.salesbox.style.height = '20px'
                this.$refs.inbox.style.overflow = 'hidden'
                this.$refs.inbox.style.height = '24px'
            }
        },
        //获取数据
        async getDetailList() {
            this.loadingInstance = this.$loading();
            const { data: { data } } = await this.$orderApis.goodsDetail({ goodsId: +this.$route.query.goodsId })
            this.goodsChecked()
            this.loadingInstance.close()
            this.goods = data
            this.direct = []
            this.bigImg = data.productGoodsImgs[0].thumbPath
            this.direct.push(data.parentCategory, data.category, data.thirdCategory)
            if (data.tip) {
                this.goods.acts.push({
                    description: data.tip,
                    actType: 8,
                })
            }
            if (data.bargain) {
                this.goods.acts.push({
                    description: data.bargain,
                    actType: 99,
                })
            }
            this.goods.buyNumber = this.goods.startingPurchase
            if (this.goods.pack.length) {
                const index = this.goods.pack.findIndex((item) => {
                    return item.isPack == 'on'
                })
                if (index !== -1) {
                    this.packAge = this.goods.pack[index].num
                } else {
                    this.packAge = this.goods.pack[0].num
                }
            } else {
                this.packAge = 1
            }
            // this.goods.acts.push({
            //     description: 12341,
            //     actType: 1,
            // })
            // this.goods.acts.push({
            //     description: 1221341,
            //     actType: 2,
            // })
            // this.goods.acts.push({
            //     description: 12123341,
            //     actType: 7,
            // })
        },
        //获取推荐商品
        async getRecommendList() {
            const { data: { data } } = await this.$orderApis.recommendList({ id: +this.$route.query.goodsId })
            this.recomendList = data.list
        },
        // 进入显示遮罩层和放大镜
        doshow() {
            this.markFlag = true;
            this.$nextTick(() => {
                if (this.$refs.largePic) {
                    this.$refs.largePic.style.backgroundImage = `url(${this.$refs.previewImg.src})`;
                }
            });
        },
        // 放大镜移动
        domove(e) {
            if (this.$refs.mark) {
                let x = e.offsetX - this.$refs.mark.offsetWidth / 2;
                let y = e.offsetY - this.$refs.mark.offsetHeight / 2;
                const maxx = this.$refs.previewPic.offsetWidth - this.$refs.mark.offsetWidth;
                const maxy = this.$refs.previewPic.offsetHeight - this.$refs.mark.offsetHeight;
                x = x < 0 ? 0 : x;
                x = x > maxx ? maxx : x;
                y = y < 0 ? 0 : y;
                y = y > maxy ? maxy : y;
                this.$refs.mark.style.left = x + "px";
                this.$refs.mark.style.top = y + "px";
                this.$refs.largePic.style.backgroundPosition = `${-2 * x}px ${-2 * y}px`;
            }
        },
        //向前切换
        doprev() {
            if (this.activeSelect > 0) {
                this.activeSelect--;
                this.$refs.previewImg.src = this.goods.productGoodsImgs[this.activeSelect].thumbPath;
            }
        },
        //向后切换
        donext() {
            if (this.activeSelect < this.goods.productGoodsImgs.length - 1) {
                this.activeSelect++;
                this.$refs.previewImg.src = this.goods.productGoodsImgs[this.activeSelect].thumbPath;
            }
        },
        //点击切换
        selectImg(item, index) {
            this.activeSelect = index;
            this.$refs.previewImg.src = item.thumbPath;
        },
        //点击添加购物车
        async addShopCar() {
            if (this.$store.state.memberInfo.isMain == 1) {
                const res = await this.$api.storeList({ goodsId: +this.$route.query.goodsId })
                console.log(res);
                if (res.data.code == 200) {

                    res.data.data.multipleMemberInfo.forEach(item => {
                        item.checked = false
                        item.quantity = 0
                    })
                    this.cartList = res.data.data.multipleMemberInfo
                    console.log(this.cartList);

                    this.farenVisible = true
                }

            } else {
                if (this.goods.stock == 0) return this.$message({
                    message: '抱歉，该商品已售罄',
                    type: "warning"
                })
                let params = {
                    goodsId: this.goods.goodsId,
                    num: this.goods.buyNumber,
                    actType: 1,
                    actId: 0,
                }
                if (this.$route.query.prepareOrderNumber) params.prepareOrderNumber = this.$route.query.prepareOrderNumber
                const res = await this.$orderApis.addCart(params)
                // if (res.data.data.state !== 200) return this.$message({
                //     message: res.data.data.message,
                //     type: "warning"
                // })
                if (res.data.data.state === 200) {
                    // 成功加入采购车后按钮状态变成已在采购车
                    this.goods.cartNumber = this.goods.buyNumber
                    this.$message({
                        message: res.data.data.message,
                        type: "success"
                    })
                } else {
                    this.$message({
                        message: res.data.data.message,
                        type: "warning"
                    })
                }
                if (this.$route.query.prepareOrderNumber) {
                    // 更新采购车数量
                    getOrderNum(this)
                } else {
                    // 更新采购车数量
                    getCarNum(this)
                }

            }

        },
        async realAddCar(cartList) {


            const arr = cartList.filter((item) => item.checked && item.quantity)
            if (!arr.length) return this.$message('请选择要购买的门店')

            console.log(arr);
            const cartMultipleMemberNum = arr.map(item => {
                return {
                    memberId: item.memberId,
                    num: item.quantity,
                }
            })
            console.log(cartMultipleMemberNum);
            const res = await this.$api.multipleAddCart({
                goodsId: +this.$route.query.goodsId,
                cartMultipleMemberNum
            })
            console.log(res);
            if (res.data.code == 200) {
                const faliList = res.data.data.filter(item => item.cartNoticeResp[0].state != 200)
                if (!faliList.length) {
                    this.$message({
                        message: '加入采购车成功',
                        type: 'success'
                    })
                } else {
                    if (cartMultipleMemberNum.length == faliList.length) {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n'),
                            type: 'warning'
                        });
                    } else {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n') + '，其余客户加入购物车成功',
                            type: 'warning'
                        });
                    }

                }
            }
            getCarNum(this)
            this.farenVisible = false

        },
        closeDialog() {
            this.farenVisible = false
        },
        copyText(text) {
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                navigator.clipboard.writeText(text);
                return this.$message.success('复制成功')
            } else {
                // document.execCommand('copy') 向剪贴板写文本
                let input = document.createElement('input')
                input.style.position = 'fixed'
                input.style.top = '-10000px'
                input.style.zIndex = '-999'
                document.body.appendChild(input)
                input.value = text
                input.focus()
                input.select()
                try {
                    let result = document.execCommand('copy')
                    document.body.removeChild(input)
                    if (!result || result === 'unsuccessful') {
                        this.$message.warning('复制失败')
                    } else {
                        this.$message.success('复制成功')
                    }
                } catch (e) {
                    document.body.removeChild(input)
                    alert('当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作')
                }
            }

        },
        async goPay() {
            const res = await this.$orderApis.addCart({
                goodsId: this.goods.goodsId,
                num: this.goods.buyNumber,
                actType: 1,
                actId: 0,

            })
            if (res.data.data.state !== 200) return this.$message({
                message: res.data.data.message,
                type: "warning"
            })
            await getCarNum(this)
            this.$router.push('/shopCar')
        },
        //点击收藏
        async collect(goodsId) {
            const res = await this.$orderApis.collect({
                goodsId
            })
            if (res.data.code !== 200) return
            this.goods.favorite = !this.goods.favorite
            this.goods.favorite ? this.$message({
                message: '收藏成功',
                type: "success"
            }) : this.$message('取消收藏成功')
        },
        // 推荐模块添加收藏
        async recCollect(goodsId, item) {
            const res = await this.$orderApis.collect({
                goodsId
            })
            if (res.data.code !== 200) return
            item.favorite = !item.favorite
            item.favorite ? this.$message({
                message: '收藏成功',
                type: "success"
            }) : this.$message('取消收藏成功')
        },
        //点击增加数量
        // increase() {
        //     if (this.goods.quota - this.goods.already <= 0) {
        //         this.count = 0
        //         return this.$message("抱歉，已超过该商品限购量")
        //     }
        //     this.goods.is_separate === 1
        //         ? this.count += this.goods.pack
        //         : this.count++;

        // },
        // //点击减少数量
        // reduce() {
        //     if (this.goods.is_separate === 1) {
        //         if (this.count <= this.goods.pack) return this.count = this.goods.pack
        //         return this.count -= this.goods.pack
        //     }
        //     if (this.count <= 1) return;
        //     this.count--;
        // },


        //推荐模块购物车加减
        recAdd(id) {
            this.recomendList.forEach(item => {
                if (item.goods_id === id) {
                    if (item.count >= item.quota - item.already) {
                        this.$message("抱歉，已超过该商品限购量")
                        item.count = item.is_separate === 1
                            ? Math.floor((item.quota - item.already) / item.pack) * item.pack
                            : item.quota - item.already;
                    } else {
                        if (item.is_separate === 1) {
                            item.count += item.pack;
                        } else {
                            item.count++;
                        }
                    }
                }
            });
        },
        recReduce(id) {
            this.recomendList.forEach(item => {
                if (item.goods_id === id) {
                    if (item.is_separate === 1) {
                        if (item.count <= item.pack) return item.count = item.pack
                        return item.count -= item.pack
                    }
                    if (item.count <= 1) return;
                    item.count--;
                }
            })
        },
        //输入数量
        recInput(id) {
            this.recomendList.forEach(item => {
                if (item.goods_id === id) {
                    if (item.count <= 0 || isNaN(item.count)) {
                        item.count = item.is_separate === 1 ? item.pack : 1;
                        return;
                    }
                    const remainingQuota = item.quota - item.already;
                    if (item.count >= remainingQuota) {
                        this.$message("抱歉，已超过该商品限购量");
                        item.count = item.is_separate === 1
                            ? Math.floor(remainingQuota / item.pack) * item.pack
                            : remainingQuota;
                    }
                }
            });
        },
        //去详情页
        goOthers(id) {
            this.$router.push('/goodsDetail?goodsId=' + id)
        },
        //批号搜索
        async codeSearch() {
            const params = {
                goods_id: this.goods.goods_id,
                batch_code: this.batch_code,
                product_code: this.goods.product_code
            }
            if (!this.batch_code) return this.$message('请输入批号')
            const res = await searchBatch(params)
            this.$message({
                message: res.data.msg,
                type: "warning"
            });
        },
        //下载商品证件
        async goodsDownload() {
            const res = await download({
                type: 0,
                id: this.goods.goods_id
            })
            console.log(res.data.data.dfile);
            handleExportZip('http://mall.huida.cc' + res.data.data.dfile, res.data.data.dfile.substring(18, 30))
        },
        //添加留言
        async writeMessage() {
            if (!this.tel || !this.content) return this.$message.warning('请填写完整')
            const res = await this.$orderApis.saveMessage({
                product_code: this.goods.productCode,
                tel: this.tel,
                content: this.content
            })
            if (res.data.code !== 200) return
            this.$message({
                message: '提交成功',
                type: "success"
            });
            this.tel = ''
            this.content = ''
        }
    },
    watch: {
        //监听数量
        count: {
            handler(val) {
                const remainingQuota = this.goods.quota - this.goods.already;
                if (remainingQuota <= 0) return this.count = 0
                if (val <= 0 || isNaN(val)) {
                    this.count = this.goods.is_separate === 1 ? this.goods.pack : 1;
                    return;
                }
                if (this.goods.is_separate === 1 && val <= this.goods.pack) {
                    this.count = this.goods.pack;
                    return;
                }
                if (val > remainingQuota) {
                    this.$message("抱歉，已超过该商品限购量");
                    this.count = this.goods.is_separate === 1
                        ? Math.floor(remainingQuota / this.goods.pack) * this.goods.pack
                        : remainingQuota;
                }
            },
            immediate: true,
        },
        activeSelect: {
            handler(val) {
                if (val >= 5) {
                    this.$refs.moveul.style.left = -((val - 4) * 65) + 'px'
                } else {
                    this.$refs.moveul.style.left = 0
                }
            }
        },
        //监听路由
        $route: {
            handler() {
                this.getDetailList()
                this.getRecommendList()

                scrollTo(0, 0)
            }
        }
    }

}
</script>

<style scoped lang="scss">
.float {
    float: left;
}
::v-deep.el-textarea .el-textarea__inner {
    font-family: Microsoft YaHei, Microsoft YaHei !important;
}
::v-deep.el-input__inner {
    font-family: Microsoft YaHei, Microsoft YaHei !important;
}
// ::v-deep.handlerTab_tooltip.is-light {
// border-color: #ebeef5 !important;
// box-shadow: 0 0 7px rgba(0,0,0,.09) !important;
// }
.tableClass {
    border-collapse: collapse;

    th,
    td {
        padding: 8px;
        text-align: center; /* 居中文本 */
    }
}
.detailPage {
    background-color: #fff;
    ::v-deep {
        .el-icon-arrow-up::before {
            content: '+';
            font-size: 20px;
        }
        .el-icon-arrow-down::before {
            content: '-';
            font-size: 20px;
        }
        .is-controls-right .el-input-number__decrease [class*='el-icon'],
        .is-controls-right .el-input-number__increase [class*='el-icon'] {
            transform: scale(1);
        }
        .el-input-number.is-controls-right .el-input-number__decrease,
        .el-input-number.is-controls-right .el-input-number__increase {
            height: 22px;
            line-height: 20px;
        }
        .el-input-number.is-controls-right .el-input-number__decrease,
        .el-input-number.is-controls-right .el-input-number__decrease {
            height: 23px;
            line-height: 27px;
        }
        .el-input-number.is-controls-right .el-input__inner {
            height: 46px;
        }
        .el-input__inner {
            color: #333 !important;
        }
        .el-textarea__inner {
            color: #333 !important;
        }
    }
}
.car-line {
    width: 0px;
    height: 10px;
    border: 1px solid #c4d4e2;
    margin: 0 10px;
}
.car-box {
    width: 186px;
    height: 22px;
    background: #ecf6ff;
    border-radius: 2px;
    margin: 0px 0 15px 10px;
    color: #4e6474;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-box {
    width: 14px;
    height: 14px;
    background: #f9f9f9;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #e8e8e8;
    font-size: 8px;
    color: #999999;
    text-align: center;
    line-height: 14px;
}
.sc_location {
    width: 1160px;
    margin: auto;
    height: 43px;
    line-height: 43px;
    color: #666;
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        li {
            list-style-type: none;
            float: left;
        }
    }
}
.tag {
    // position: absolute;
    // left: 10px;
    // bottom: 0;
    display: flex;
    align-items: center;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 21px;
        box-sizing: border-box;
        border-radius: 3px 3px 3px 3px;
        font-size: 12px;
        line-height: 0px;
    }

    .tj {
        background: rgba(248, 213, 213, 0.9);
        border: 1px solid #ffacac;
        color: #f92b2b;
        margin-right: 4px;
        width: auto;
    }
    .zy {
        background: rgba(206, 231, 249, 0.9);
        border: 1px solid #b7dcf8;
        color: #1a9dff;
        margin-right: 4px;
    }
    .yh {
        width: auto;
        padding: 0 6px;
        background: rgba(248, 240, 223, 0.9);
        border: 1px solid #fad894;
        color: #f7a400;
    }
}
.sc_middle {
    // width: 1160px;
    // height: 460px;
    // height: 100%;
    // margin: auto;
    // border: 1px solid #fff;
    // margin-bottom: 30px;
    display: flex;
    .leftPic {
        width: 380px;
        height: 520px;
        // float: left;
        .goodsPic {
            width: 380px;
            height: auto;
            margin: 0 auto;
            .previewPic {
                width: 378px;
                height: 378px;
                border: 1px solid #ebebeb;
                position: relative;
                cursor: move;
                .act_tag {
                    width: 88px;
                    height: 112px;
                    background: url('http://mall.huida.cc/picture/home/activity_tag.png?v=1.0.0')
                        no-repeat;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-position: 0px -119px;
                    pointer-events: none;
                }
                .special_tag {
                    display: block;
                    width: 98px;
                    height: 98px;
                    background: url('http://mall.huida.cc/picture/home/activity_tag.png?v=1.0.0')
                        no-repeat;
                    position: absolute;
                    background-position: -282px 0;
                    font-style: normal;
                    top: 0;
                    left: 0;
                }
                .promotion_tag {
                    display: block;
                    width: 98px;
                    height: 98px;
                    background: url('http://mall.huida.cc/picture/home/gifts_tag.png?v=1.0.0')
                        no-repeat;
                    position: absolute;
                    font-style: normal;
                    top: 0;
                    left: 0;
                }
                .mark {
                    width: 143px;
                    height: 154px;
                    background-color: #fff;
                    opacity: 0.5;
                    border: 1px solid #dfdfdf;
                    position: absolute;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    z-index: 99;
                }
                .largePic {
                    width: 378px;
                    height: 378px;
                    // background: url('../../assets/img/ic_no_goods.png')
                    // no-repeat;
                    background-size: 850px 850px;
                    position: absolute;
                    left: 390px;
                    top: 0;
                    z-index: 99;
                }
            }
            .scrollPic {
                clear: both;
                // width: 378px;
                height: 75px;
                .prev {
                    width: 18px;
                    height: 65px;
                    background: #7a7a7a;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    float: left;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.4;
                    }
                }
                .no-move {
                    opacity: 0.1 !important;
                }
                .next {
                    width: 18px;
                    height: 65px;
                    background: #7a7a7a;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 0.8;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    float: right;
                    &:hover {
                        opacity: 0.4;
                    }
                }
                .items {
                    float: left;
                    position: relative;
                    width: 340px;
                    height: 70px;
                    overflow: hidden;
                    ul {
                        position: absolute;
                        height: 60px;
                        margin: 5px;
                        display: flex;
                        justify-content: space-between;
                        li {
                            width: 60px;
                            text-align: center;
                            margin-left: 5px;
                            height: 60px;
                            position: relative;
                            i {
                                display: block;
                                border-right: 5px solid transparent;

                                border-left: 5px solid transparent;
                                width: 0px;
                                height: 0px;
                                position: absolute;
                                top: -5px;
                                z-index: 99;
                                left: 25px;
                            }
                            img {
                                padding: 2px;
                                width: 47px;
                                height: 55px;
                            }
                        }
                    }
                }
            }
        }
        .tooltip {
            color: #333333;
            font-size: 16px;
        }
    }
    .rightDesc {
        width: 740px;
        float: left;
        margin-left: 25px;
        color: #333;
        font-family: 思源黑体;
        letter-spacing: 0.5px;
        .goodsname {
            width: 740px;
            ul {
                margin: 0px;
                padding: 0px;
                li {
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    list-style: none;
                    margin-bottom: 18px;
                    // float: left;
                    line-height: 30px;
                }
            }
            .product-money {
                width: 710px;
                height: 72px;
                background: #f7f8fb;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;
                i {
                    font-style: normal;
                }
                .product-money-left {
                    display: flex;
                    .gongyin {
                        display: flex;
                        align-items: center;
                        .discount {
                            font-size: 17px;
                            color: #999999;
                            margin-right: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .count {
                            display: flex;
                            .discount-m {
                                font-size: 18px;
                                color: #f92b2b;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                            .count-m {
                                font-size: 28px;
                                color: #f92b2b;
                                font-weight: bold;
                                margin-top: -5px;
                            }
                        }
                    }

                    .lingshou {
                        display: flex;
                        margin-left: 20px;
                        justify-content: center;
                        align-items: center;
                        .sale-mark {
                            font-size: 22px;
                            color: #333333;
                            display: flex;
                            align-items: center;
                        }
                        .sale-m {
                            font-size: 26px;
                            color: #333333;
                            margin-top: -5px;
                            font-weight: bold;
                        }
                    }
                    .coupon-later {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 20px;
                        span {
                            padding: 5px 8px;
                            border-radius: 100px;
                            background: #ffeaea;
                            color: #f92b2b;
                        }
                    }
                }
            }
            .red {
                background: #f9f2f2 !important;
            }
            .blue {
                background: #f9fdff !important;
            }
            .one-salebox {
                height: 100% !important;
            }
            .salesbox {
                width: 730px;
                height: 20px;
                // padding: 12px;

                font-size: 12px;
                color: #666666;
                position: relative;
                background-size: 100% 100%;
                // z-index: 99;
                //    margin-bottom: 44px;
                b {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
                // &:hover {
                //     height: auto;
                //     position: absolute;
                //     .inbox {
                //         overflow: visible;
                //         height: 100%;
                //     }
                // }
                .reward {
                    background: #f9f2f2 !important;
                }
                .yijia {
                    background: #f9fdff !important;
                }
                .over-inbox {
                    overflow: hidden;
                }
                .inbox {
                    display: block;
                    line-height: 24px;
                    height: 24px;
                    margin-right: 16px;
                    background: #fffbf5;
                    width: 100%;
                    height: 100%;
                    padding: 10px;

                    li {
                        font-size: 12px;
                        display: block;
                        line-height: 20px;
                        height: auto;
                        margin-bottom: 3px;
                        margin-right: 16px;
                        i {
                            width: 54px;
                            height: 24px;
                            color: #fff;
                            text-align: center;
                            // float: left;
                            font-size: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: linear-gradient(
                                179deg,
                                #ff9c83 0%,
                                #e76c25 100%
                            );
                            margin-top: -2px;
                        }
                        .saleContent {
                            width: 590px;
                            line-height: 20px;
                            margin-left: 96px;
                        }
                    }
                }
            }
        }
        .sc_content {
            display: flex;
            flex-direction: column;
            line-height: 32px;

            .sc_content_top {
                width: 740px;
                padding-bottom: 10px;
                border-bottom: 1px dashed #f0f0f0;
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                float: left;
                .sc_content_quality {
                    flex: 0.65;
                    margin-right: 30px;
                }
            }
            .first {
                margin-right: 10px;
                color: #999999;
                font-size: 16px;
            }
            .second {
                color: #333333;
                font-size: 16px;
            }
            .mid_content {
                border-bottom: 1px dashed #f0f0f0;
                padding-bottom: 10px;
                display: flex;
                .mid_first {
                    display: flex;
                    flex-direction: column;
                    padding-right: 20px;
                }
            }
        }
        .shopping {
            height: 36px;
            margin-top: 20px;
            display: flex;

            .computed {
                width: 96px !important;
                .numdown {
                    width: 29px;
                    height: 23px;
                    background-color: #f6f6f6;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    border: 1px solid #e2e2e2;
                    font-size: 16px;
                    font-weight: normal;
                    color: #333333;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    -webkit-backface-visibility: hidden;
                    float: right;
                    line-height: 14px;
                    margin-right: -1px;
                }
                .numup {
                    width: 29px;
                    height: 23px;
                    background-color: #f6f6f6;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    border: 1px solid #e2e2e2;
                    font-size: 16px;
                    font-weight: normal;
                    color: #333333;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    -webkit-backface-visibility: hidden;
                    margin-right: -1px;
                    text-align: center;

                    line-height: 14px;
                    float: right;
                }
                .countbox {
                    width: 60px;
                    height: 46px;
                    border-radius: 0px 0px 0px 0px;
                    border: 1px solid #e2e2e2;
                    border-right: 0;
                    background: #fff;
                    float: left;
                    text-align: center;
                    margin-right: -1px;
                    font-size: 18px;
                    color: #666;
                    line-height: 33px;
                    outline: 0;
                }
                .xiangou {
                    font-size: 12px;
                    font-weight: 500;
                    color: #b8b8b8;
                    float: left;
                    line-height: 20px;
                }
            }

            .addcar {
                width: 140px;
                float: left;
                margin-left: 30px;
                text-decoration: none;
                a {
                    width: 144px;
                    box-sizing: border-box;
                    // line-height: 38px;
                    text-align: center;
                    font-size: 20px;
                    color: #fff;
                    font-family: 'monospace';
                    margin-bottom: 17px;
                    // background: #d63103;
                    border-radius: 2px;
                    text-decoration: none;
                    // height: 49px !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                }
                .addcar_btn {
                    background: #0687fe;
                    border: none;
                    color: #ffffff;
                    border-radius: 4px;
                    height: 46px !important;
                    &:hover {
                        background: linear-gradient(#4daef6 0%, #0687fe 100%);
                    }
                }
                .disabled {
                    background: linear-gradient(
                        181deg,
                        #86c8f9 0%,
                        #7cbdf9 100%
                    );
                    border: none;
                    color: #ffffff;
                    border-radius: 4px;
                    height: 46px !important;
                }
                .exist {
                    background: linear-gradient(
                        182deg,
                        #31c19e 0%,
                        #14ac87 100%
                    );

                    &:hover {
                        background: linear-gradient(
                            182deg,
                            #14ac87 0%,
                            #31c19e 100%
                        );
                        cursor: pointer;
                        transition: 0.3s;
                    }
                    &.disabled,
                    &.disabled:hover {
                        background: linear-gradient(
                            182deg,
                            #91dac8 0%,
                            #89d6c3 100%
                        );
                    }
                }
            }
            .buycar {
                width: 140px;
                float: left;
                margin-left: 30px;
                text-decoration: none;

                a {
                    background: #ecf6ff;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #6cb4f6;
                    text-decoration: none;
                    height: 49px !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    width: 138px;
                    line-height: 38px;
                    text-align: center;
                    font-size: 20px;
                    font-family: 'monospace';
                    color: #1a9dff;
                    // opacity: 1.9 !important;
                }
            }
            .collect {
                color: #fff;
                border-radius: 1px;
                width: 109px;
                opacity: 1;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 6px;
                margin-left: 30px;
                position: relative;
                font-size: 16px;
                font-family: 'monospace';
                text-align: left;
                cursor: pointer;
                .line {
                    position: absolute;
                    top: 24%;
                    left: 0;
                    width: 1px;
                    height: 20px;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    background-color: #e3e5ea;
                }
            }
        }
    }
}
.cutline {
    width: 1px;
    height: 36px;
    opacity: 1;
    background-color: #e3e5ea;
    margin-left: 60px;
}
.liselected {
    border: 1px solid #1a9dff;
}
.iselected {
    border-bottom: 5px solid #1a9dff;
}
.sc_foot {
    // width: 1160px;
    // margin: 50px auto;
    clear: both;
    margin-bottom: -20px;
    margin-top: 0;
    display: flex;
    .recomend {
        width: 222px;
        // height: 865px /*810px*/;
        clear: both;
        // float: left;
        .recgoods {
            width: 220px;
            height: 46px;
            border: 1px solid #eeeeee;
            color: #5e5e5e;
            font-size: 16px;
            background: #f7f7f7;
            line-height: 46px;
            border-bottom: 0;
            text-align: center;
            box-sizing: border-box;
        }
        .defaultRecomend {
            border: 1px solid #eeeeee;
            width: 218px;
            padding: 15px 0;
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
            p {
                margin: 10px 15px 0;
                font-size: 14px;
                text-align: left;
                color: #ccc;
            }
        }
        .recomendPic {
            margin: 0px;
            margin-bottom: 6px;
            width: 220px;
            height: auto;
            float: left;
            position: relative;
            font-size: 12px;
            color: rgb(102, 102, 102);
            font-family: 思源黑体;
            box-sizing: border-box;
            border: 1px solid #eeeeee;
            border-image: initial;
            background: rgb(255, 255, 255);
            // &:hover {
            //     border: 1px solid #d63103;
            //     .store {
            //         display: block;
            //         border: 1px solid #d63103;
            //     }
            //     .collectImg {
            //         display: block;
            //         img {
            //             overflow: hidden;
            //             animation: expandImg 0.5s forwards;
            //         }
            //     }
            // }
            // @keyframes expandImg {
            //     0% {
            //         height: 0;
            //     }
            //     100% {
            //         height: 100%;
            //     }
            // }
            p {
                height: 20px;
                line-height: 20px;
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-wrap: normal;
                overflow: hidden;
            }
            .recCar {
                height: 33px;
                margin-left: 10px;
                margin-top: 7px;
                .counter {
                    width: 88px;
                    height: 23px;
                    border: 1px solid #ccc;
                    float: left;
                    margin-right: 14px;
                    a {
                        width: 20px;
                        line-height: 23px;
                        height: 23px;
                        color: #ccc;
                        float: left;
                        text-align: center;
                        text-decoration: none;
                    }
                    input {
                        width: 46px;
                        height: 23px;
                        line-height: 23px;
                        text-align: center;
                        border: 1px solid #ccc;
                        border-top: none;
                        border-bottom: none;
                        float: left;
                        margin: 0px;
                        padding: 0px;
                    }
                }
                .add-car {
                    height: 49px !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    border: 1px solid #d55603;
                    background: #d63103;
                    color: #fff;
                    float: right;
                    width: 90px;
                    line-height: 23px;
                    text-align: center;
                    margin-right: 10px;
                }
            }
            .store {
                width: 220px;
                bottom: -52px;
                background: #fff;
                border-top: none;
                height: 40px;
                padding: 5px 0;
                position: absolute;
                left: -1px;
                z-index: 2;
                display: none;
                p {
                    height: 20px;
                    line-height: 20px;
                    margin-left: 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: normal;
                    overflow: hidden;
                }
            }
            .recImg {
                margin: 15px 9px;
                height: 200px;
                overflow: hidden;
                text-align: center;
                position: relative;
                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                    margin: auto;
                    border: none;
                    vertical-align: middle;
                }
                .spe-price {
                    width: 33px;
                    height: 21px;
                    background: #f8d5d5;
                    border-radius: 3px 3px 3px 3px;
                    border: 1px solid #ffacac;
                    font-weight: 400;
                    font-size: 12px;
                    color: #f92b2b;
                    text-align: center;
                    line-height: 21px;
                }
                .own {
                    width: 33px;
                    height: 21px;
                    background: #cee7f9;
                    border-radius: 3px 3px 3px 3px;
                    border: 1px solid #b7dcf8;
                    font-size: 12px;
                    color: #1a9dff;
                    margin-left: 10px;
                    text-align: center;
                    line-height: 21px;
                }
            }
            .collectImg {
                width: 26px;
                height: 26px;
                background: #cbcbcb;
                border-radius: 4px 4px 4px 4px;
                // opacity: 0.2;
                position: absolute;
                right: 0;
                top: 0;
                line-height: 25px;
                text-align: center;
            }
        }
    }
    .goodsdetail {
        width: 920px;
        height: auto;
        // float: left;
        margin-left: 16px;
        .detailList {
            width: 921px;
            height: 47px;

            border-bottom: 1px solid #1a9dff;
            // border-right: 1px solid #ccc;
            // border-left: 1px solid #ccc;
            background: #f7f9f8;
            position: relative;
            // border-top: 1px solid #ccc;
            ul {
                margin-left: 1px;
                list-style: none;

                li {
                    border-top: none;
                    margin-left: -1px;
                    float: left;
                    font-size: 16px;
                    line-height: 47px;
                    text-align: center;
                    width: 140px;
                    height: 47px;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    color: #5e5e5e;
                    position: relative;
                }
            }
        }
        .detailContent {
            border-top: 0;
            font-size: 16px;
            font-family: '思源黑体';
            width: 100%;
            height: auto;
            float: left;
            border: 1px solid #eeeeee;
            border-top: 1px solid #1a9dff;
            li {
                margin: 24px auto 20px auto;
                overflow: hidden;
                width: 850px;
                height: auto;
                line-height: 24px;
                font-size: 14px;
                min-height: /*680px*/ 745px;
                .addbtn {
                    border: 1px;
                    width: 71px;
                    height: 33px;
                    background-color: #d63103;
                    margin: 6px 16px 0 35px;
                    color: white;
                    line-height: 34px;
                    padding-left: 18px;
                    font-size: 14px;
                    margin-left: 312px;
                    float: right;
                }
                img {
                    overflow-clip-margin: content-box;
                    overflow: clip;
                    width: 100%;
                }
                .search {
                    padding: 20px 0;
                    width: 500px;
                    input {
                        font-family: inherit;
                        font-size: inherit;
                        font-style: inherit;
                        font-weight: inherit;
                        outline: 0;
                        border: 1px solid #ccc;
                    }
                    .a1 {
                        display: block;
                        height: 24px;
                        background-color: #69bcd7;
                        float: right;
                        text-align: center;
                        line-height: 24px;
                        color: #fff;
                        margin-left: 20px;
                        width: 100px;
                    }
                    .a2 {
                        display: block;
                        height: 24px;
                        background-color: #69bcd7;
                        float: right;
                        text-align: center;
                        line-height: 24px;
                        color: #fff;
                        width: 70px;
                    }
                }
            }
        }
    }
}
.i1 {
    display: block;
    width: 142px;
    height: 2px;
    background: #ff8c00;
    position: absolute;
    top: -1px;
    left: -1px;
}
.i2 {
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ff8c00;
    top: 1px;
    left: 65px;
    position: absolute;
}
.selectLi {
    // border-left: 1px solid #ccc;
    // border-right: 1px solid #ccc;
    background: #1a9dff;
    color: #fff !important;
}
.selectLiFirst {
    border-right: 1px solid #ccc;
    background: #fff;
}
.defaultBorder {
    border-left: none;
}
.subject {
    border-top: 1px solid #f2f2f2;
    padding: 5px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lcardot3 {
    position: absolute;
    top: -12px;
    right: 5px;
    min-width: 22px;
    padding: 1px 2px;
    height: 15px;
    border-radius: 10px;
    // transform: scale(1.5);
    background-color: red;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
}
.box-con {
    width: 208px;
    height: 77px;
    position: absolute;
    background: #0687fe;
    top: -120px;
    right: -57px;
    transform: rotate(45deg);
}
.box-con img {
    position: absolute;
    bottom: 3px;
    left: 190px;
    transform: rotate(-45deg);
}
</style>
<style lang="scss">
.handlerTab_tooltip.el-tooltip__popper.is-light {
    border-color: #ebeef5 !important;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.09) !important;
}
.handlerTab_tooltip.el-tooltip__popper.is-light[x-placement^='bottom']
    .popper__arrow::after {
    margin-left: -6px;
    border-top-color: transparent !important;
    border-bottom-color: #fff !important;
}

.new-dialog .el-dialog .el-dialog__title {
    font-weight: 700 !important;
}
.new-dialog .el-checkbox__label {
    color: #333 !important;
}
.new-dialog .checkbox .el-checkbox__label {
    color: #999 !important;
}
.new-dialog .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0054bc !important;
    border-color: #0054bc !important;
}
.new-dialog .el-input-number--small {
    width: 120px;
}
.tipDialog .el-dialog__header {
    padding: 0 !important;
}
.tipDialog .el-dialog {
    border-radius: 16px 16px 16px 16px;
    width: 316px !important;
}
.tipDialog .el-dialog__body {
    padding: 20px 15px 12px;
}
.priceBtn {
    // width: 279px;
    height: 42px;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 12px;
    font-size: 14px;
    color: #111111;
}
.selectBtn {
    background: #0687fe;
    color: #fff;
    .contact {
        color: #fff !important;
    }
}
.lineHeight {
    line-height: 20px;
}
.el-radio__input{
    display: none;
}
</style>