<template>
    <!-- 个人中心订单详情 -->
    <div class="type_area">
        <div class="order_detail">
            <!-- 订单编号 -->
            <div class="order_info">
                <div class="receive_info" style="display: flex;justify-content: space-between;align-items: center;width: 100%;margin-bottom: 5px;">
                    <div class="box_title">发起人信息</div>
                    <div @click="backList" @mouseenter="on=true" @mouseleave="on=false"  style="font-size: 14px;color: #999999;display: flex;align-items: center;cursor: pointer;"> <img v-if="on" src="@/assets/images/personal/blueBack.svg" alt=""> <img v-if="!on" src="@/assets/images/personal/back.svg" alt=""><span v-if="on" style="color: #337cff;">返回</span><span v-if="!on" >返回</span></div>
                </div>
            </div>
            <div class="info_box" style="margin-bottom: 20px;">
                <div class="info_item">
                    <div style="display: flex;font-size: 16px;color: #666666;">
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">创建人</div>
                            <div style="color: #333333;">{{baseInfo.createPerson}}</div>
                        </div>
                        <div style="width: 400px;display: flex;">
                            <div style="width: 120px;">创建时间</div>
                            <div style="color: #333333;">{{baseInfo.createTime}}</div>
                        </div>
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">所属部门</div>
                            <div style="color: #333333;">{{baseInfo.departName}}</div>
                        </div>
                    </div>
                    <div style="display: flex;font-size: 16px;color: #666666;margin-top: 10px;">
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">原计划负责人</div>
                            <div style="color: #333333;">{{baseInfo.originalProposer}}</div>
                        </div>
                        <div style="width: 400px;display: flex;">
                            <div style="width: 120px;">现客户负责人</div>
                            <div style="color: #333333;">{{baseInfo.currentManager}}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="order_info">
                <div class="receive_info">
                    <div class="box_title">退货信息</div>
                </div>
            </div>
            <div class="info_box" style="margin-bottom: 20px;">
                <div class="info_item">
                    <div style="display: flex;font-size: 16px;color: #666666;">
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">法人多店退货</div>
                            <div style="color: #333333;">{{baseInfo.isMain?'是':'否'}}</div>
                        </div>
                        <div style="width: 400px;display: flex;">
                            <div style="width: 120px;">客户名称</div>
                            <div style="color: #333333;">{{baseInfo.companyName}}</div>
                        </div>
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">所属省市区</div>
                            <div style="color: #333333;">{{baseInfo.region}}</div>
                        </div>
                    </div>
                    <div style="display: flex;font-size: 16px;color: #666666;margin-top: 10px;">
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">联系人</div>
                            <div style="color: #333333;">{{baseInfo.contacter}}</div>
                        </div>
                        <div style="width: 400px;display: flex;">
                            <div style="width: 120px;">发货日期</div>
                            <div style="color: #333333;">{{baseInfo.outDate}}</div>
                        </div>
                        <div style="display: flex;flex: 1;">
                            <div style="width: 120px;">退货原因</div>
                            <div style="color: #333333;width: 300px;">{{baseInfo.reason}}</div>
                        </div>
                    </div>
                    <div style="display: flex;font-size: 16px;color: #666666;margin-top: 10px;">
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">发货金额</div>
                            <div style="color: #333333;font-weight: 700;">{{baseInfo.outMoney}}</div>
                        </div>
                        <div style="width: 400px;display: flex;">
                            <div style="width: 120px;">退货金额</div>
                            <div style="color: #333333;font-weight: 700;">{{baseInfo.ReturnMoney}}</div>
                        </div>
                        <div style="width: 300px;display: flex;">
                            <div style="width: 120px;">退货清单</div>
                            <div style="color: #333333;">
                                <el-link v-if="baseInfo.returnListUrl" :underline="false" class="link" @click="openDetail(baseInfo.returnListUrl)" type="primary">退货清单</el-link>
                                <!-- <a v-if="baseInfo.returnListUrl" :href="baseInfo.returnListUrl" target="_blank">退货清单</a> -->
                                <span v-else>--</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="order_info">
                <div class="receive_info" style="display: flex;justify-content: space-between;align-items: center;width: 100%;margin-bottom: 10px;">
                    <div class="box_title">退货商品明细</div>
                    <div style="display: flex;" v-if="!$route.query.type">
                        <div class="downBtn" @click="refundVisible=true"><img src="@/assets/images/personal/upRefund.svg" alt=""> <span>上传退货商品明细</span></div>
                        <div class="downBtn" @click="selectGoods"><span>选择退货商品</span></div>
                        <div class="downBtn1" style="color: #FF4646; border-color: #FF4646;" @click="batchRemove"> <span>批量移除</span></div>
                    </div>
                </div>
            </div>
            <div class="info_box" style="margin-bottom: 20px;">
                <div class="table_mode">
                    <el-table :data="tableData" ref="tableData" height="350" v-loading="loading" show-summary :summary-method="getSummaries" stripe style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="productName" label="商品名称" min-width="110" :show-overflow-tooltip="true" align="center">
                        </el-table-column>
                        <el-table-column prop="companyName" label="客户名称" min-width="130" :show-overflow-tooltip="true" align="center">
                        </el-table-column>
                        <el-table-column prop="productCode" label="商品编码" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                        <el-table-column prop="norms" label="商品规格" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                        <el-table-column prop="produceUnit" label="生产厂家" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                        <el-table-column prop="batchCode" label="商品批号" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                        <el-table-column prop="returnNum" label="退货数量" min-width="90" :show-overflow-tooltip="true" align="center">
                            <template slot-scope="scope">
                                <el-input v-if="!$route.query.type" v-model="scope.row.returnNum" placeholder="请输入" @change="changeNum(scope.row, scope.$index)"></el-input>
                                <div v-else>{{ scope.row.returnNum }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="returnMoney" label="退货金额" min-width="90" :show-overflow-tooltip="true" align="center">
                            <template slot-scope="scope">
                                <div>{{ scope.row.returnMoney.toFixed(2) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作 " width="130" align="center">
                            <template slot-scope="scope">
                                <div class="table_btn" v-if="!$route.query.type">
                                    <div style="font-size: 14px;color: #F92B2B;" class="btn_item" @click="remove(scope.$index)">移除</div>
                                </div>
                                <div v-else>--</div>
                            </template>
                        </el-table-column>
                        <div slot="empty" class="nodata_box" style="width: 1200px;">
                            <img src="@/assets/images/index/nodata.png" />
                            <span class="txt">暂无数据哦~</span>
                        </div>
                    </el-table>
                </div>
                <!-- <div class="pagination_box">
                    <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getInvoiceList" @size-change="getInvoiceList(1)">
                    </el-pagination>
                </div> -->

            </div>
            <div class="order_info">
                <div class="receive_info">
                    <div class="box_title">快递信息</div>
                </div>
            </div>
            <div class="info_box">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                    <el-form-item label="快递公司 " prop="logisticsName">
                        <el-input v-if="!$route.query.type" style="width: 400px;" v-model="ruleForm.logisticsName" placeholder="请输入"></el-input>
                        <div v-else-if="ruleForm.logisticsName">{{ruleForm.logisticsName  }}</div>
                        <div v-else>--</div>
                    </el-form-item>
                    <el-form-item label="快递单号 " prop="logisticsNo">
                        <el-input v-if="!$route.query.type" style="width: 400px;" v-model="ruleForm.logisticsNo" placeholder="请输入"></el-input>
                        <div v-else-if="ruleForm.logisticsNo">{{ruleForm.logisticsNo  }}</div>
                        <div v-else>--</div>
                    </el-form-item>
                    <el-form-item label="客户退货单 " prop="returnOrderUrl">
                        <el-upload v-if="!$route.query.type" ref="upload_attach" :limit="1"  action="" :auto-upload="false" :on-change="onChange" :on-remove="onRemove" :file-list="fileList">
                            <!-- <el-button size="small" type="primary">上传文件</el-button> -->
                            <div class="downBtn2"><img src="@/assets/images/personal/upfile.svg" alt=""> <span>上传文件</span></div>
                            <div slot="tip" class="el-upload__tip" style="margin-top: -5px;width: 300px;">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
                            <div slot="file" slot-scope="{ file }">
                                <li class="el-upload-list__item is-success">
                                    <a @click="() => openUrl(file,fileList)" class="el-upload-list__item-name">
                                        <i class="el-icon-document"></i>{{ file.name }}
                                    </a>
                                    <label class="el-upload-list__item-status-label">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                    </label>
                                    <i @click.stop="$refs.upload_attach.handleRemove(file)" class="el-icon-close"></i>
                                </li>
                            </div>
                        </el-upload>
                        <!-- <a v-else-if="baseInfo.returnOrderUrl" :href="baseInfo.returnOrderUrl" target="_blank">客户退货单</a> -->
                        <el-link v-else-if="baseInfo.returnOrderUrl" class="link" :underline="false" @click="openDetail(baseInfo.returnOrderUrl)" type="primary">客户退货单</el-link>
                        <div v-else>--</div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="order_info" v-if="!$route.query.type">

            </div>
            <div style="text-align: end;" v-if="!$route.query.type">
                <el-button @click="resetForm('ruleForm')">取消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>

            </div>
        </div>

        <el-dialog title="选择商品" :visible.sync="goodsVisible" width="70%" @closed="goodsClose">
            <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
                <div class="search-item">
                    <span>关键字：</span>
                    <el-input style="width: 300px;" clearable placeholder="出库单号/商品名称/商品编码/客户名称" size="small" v-model="form.keyword"></el-input>
                </div>
                <div class="search-item">
                    <span>商品批号：</span>
                    <el-input style="width: 300px;" clearable placeholder="商品批号" size="small" v-model="form.batchCode"></el-input>
                </div>
                <div class="btn_box" style="display: flex;">
                    <el-button class="btn_item" type="primary" size="small" @click="selectGoods">查询</el-button>
                    <el-button class="btn_item" size="small" @click="resetSearch">重置</el-button>
                </div>
            </div>
            <el-table :data="gridData" ref="multipleTable" height="500" :header-cell-style="cellStyle" stripe @selection-change="selectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="deliveryOrderNumber" label="出库单号" width="120">
                </el-table-column>
                <el-table-column prop="outTime" label="出库时间" width="180">
                </el-table-column>
                <el-table-column prop="productName" width="120" label="商品名称">
                </el-table-column>
                <el-table-column prop="companyName" label="客户名称">
                </el-table-column>
                <el-table-column prop="productCode" label="商品编码" width="120">
                </el-table-column>
                <el-table-column prop="batchCode" label="商品批号" width="120">
                </el-table-column>
                <el-table-column label="商品规格" prop="norms" width="120">
                </el-table-column>
                <el-table-column label="生产厂家" prop="produceUnit" width="120">
                </el-table-column>
            </el-table>
            <div class="pagination_box">
                <el-pagination @size-change="handleSizeChange" :total="total" @current-change="handleCurrentChange" :current-page="form.page" :page-sizes="[10, 20, 30, 40]" :page-size="form.pageSize" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="goodsVisible = false">取 消</el-button>
                <el-button type="primary" @click="selectSure">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="refundVisible" class="mydialog" title="上传退货商品明细" width="30%" @close="refundClose">
            <div>
                <el-steps direction="vertical">
                    <el-step title="步骤 一：下载模板">
                        <template slot="icon">
                            <div class="qiu"></div>
                        </template>
                        <template slot="title">
                            <div style="color: #333;font-weight: 700;">步骤 一：下载模板</div>
                        </template>
                        <template slot="description">
                            <div style="color: #999;margin-bottom: 10px;">点击【下载导入模板】按钮，可下载导入模板</div>
                            <div style="display: flex;margin-bottom: 30px;"><el-button @click="downLoadTemplate">下载导入模板</el-button>
                                <el-button @click="downLoadAll">下载华人商品列表</el-button>
                            </div>
                        </template>

                    </el-step>
                    <el-step title="">
                        <template slot="icon">
                            <div class="qiu"></div>
                        </template>
                        <template slot="title">
                            <div style="color: #333;font-weight: 700;">步骤二：上传文件</div>
                        </template>
                        <template slot="description">
                            <div style="color: #666;font-size: 14px;margin-bottom: 5px;">退货商品</div>
                            <el-upload :limit="1" action="" :auto-upload="false" :on-change="onGoodsChange" :on-remove="onGoodsRemove" :file-list="fileList1">
                                <!-- <el-button size="small" type="primary">上传文件</el-button> -->
                                <div class="downBtn2"><img src="@/assets/images/personal/upfile.svg" alt=""> <span>上传文件</span></div>
                                <div slot="tip" class="el-upload__tip" style="color: #CCCCCC;">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
                            </el-upload>
                        </template>
                    </el-step>
                </el-steps>
            </div>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button @click="refundClose" style="width: 160px;height: 42px;">取消</el-button>
                    <el-button type="primary" style="width: 160px;height: 42px;" @click="refundUserExcel">
                        开始导入
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="exportVisible" :title="''" width="25%" @close="percentClose">
            <div style="text-align:center;">
                <img style="width: 151px;height: 88px;margin-bottom: 27px;" src="@/assets/images/personal/over.png" alt="">
                <div style="color:#666;margin-bottom: 60px;">共导入{{exportTotal}}条数据，成功{{successNum}}条，失败<span style="color: #F92B2B ;">{{failNum}}</span> 条，<span @click="downLoadFail" style="color: #0687FE;cursor: pointer;">点击下载</span> 失败数据</div>
                <el-button style="width: 290px;height: 46px;" type="primary" @click="knowIt">
                    知道了
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { handlerTableData, downloadExcelFile } from '@/utils/common'
import priceDialog from '@/views/shopCar/component/priceDialog.vue'
export default {
    name: 'OrderPersDetail',
    components: {
        priceDialog
    },
    data() {
        return {
            tableData: [

            ],
            ruleForm: {
                logisticsNo: '',
                logisticsName: '',
                returnOrderUrl: '',
            },
            rules: {
                logisticsNo: [
                    { required: true, message: '请输入快递单号', trigger: 'blur' },
                ],

                logisticsName: [
                    { required: true, message: '请输入快递公司', trigger: 'blur' },
                ],
                returnOrderUrl: [
                    { required: true, message: '请上传客户退货单', trigger: 'change' },
                ],
            },
            total: 0,
            summary: {},
            payImg: '',
            loading: false,
            on: false,
            refundVisible: false,
            exportVisible: false,
            price: 369.36,
            orderNumber: '',
            baseInfo: {},
            gridData: [],
            fileList1: [],
            form: {
                id: +this.$route.query.id,
                keyword: "",
                batchCode: "",
                page: 1,
                pageSize: 10
            },
            cartInfo: {},
            multipleSelection: [],
            selectionArr: [],
            upList: [],
            tableList: [],
            orderSteps: [],
            fileList: [],
            outOrderList: [],
            invoiceList: [],
            failNum: '',
            exportTotal: '',
            successNum: '',
            logisticDialog: false,
            goodsVisible: false,
            cellStyle: {
                backgroundColor: '#fafafa'
            },
            tipVisible: false,
        };
    },
    created() {
        this.ticketDetail()
    },
    mounted() {


    },
    updated() {
        // 在vue渲染结束后重新绘制表格,解决合计行不显示的问题
        this.$nextTick(() => {
            this.$refs.tableData.doLayout();
        })
    },
    computed: {
    },
    methods: {
        storeChecked(val) {
            console.log(val);
            this.tableList.forEach(item => {
                item.checked = val
            })
            console.log(this.tableList);
        },
        changeNum(item, index) {
            console.log(item, index);
            this.tableData[index].returnMoney = item.returnNum * item.price

        },
        openUrl(file){
            console.log(file,this.fileList);
            window.open(this.ruleForm.returnOrderUrl)
        },
        handleRemove(file){
            console.log(file,this.fileList);
        },
        async downLoadAll() {
            const res = await this.$api.exportAll()
            if (res.status == 200) {
                downloadExcelFile(res)
            }
        },
        async downLoadFail() {
            const res = await this.$api.exportFail({ id: +this.$route.query.id })
            if (res.status == 200) {
                downloadExcelFile(res)
            }
        },

        async ticketDetail() {
            const res = await this.$api.writeTicket({ id: +this.$route.query.id })
            this.baseInfo = res.data.data.baseInfo
            this.tableData = res.data.data.returnItems
            this.ruleForm.logisticsName = res.data.data.baseInfo.logisticsName
            this.ruleForm.logisticsNo = res.data.data.baseInfo.logisticsNo
            if (res.data.data.baseInfo.returnOrderUrl) {
                this.ruleForm.returnOrderUrl = res.data.data.baseInfo.returnOrderUrl
                this.fileList.push({
                    url: res.data.data.baseInfo.returnOrderUrl,
                    name: new Date().getTime()
                })
            }
        },
        submitForm() {
            console.log(this.ruleForm, this.tableData);

            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.subTicket({
                        ...this.ruleForm,
                        id: +this.$route.query.id,
                        returnItems: this.tableData.map(item => {
                            return {
                                id: item.id,
                                returnNum: +item.returnNum,
                                goodsId: item.goodsId,
                                outboundId: item.outboundId,
                            }
                        })
                    })
                    if (res.data.code == 200) {
                        this.$message.success('完善单据成功')
                        this.$router.go(-1)
                    } else {
                        //    this.$message.error(res.data.msg)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //清空数据
        resetForm() {
            this.$router.go(-1)
        },
        selectSure() {
            const newArr = []
            this.selectionArr.forEach(item => {
                const index = this.tableData.findIndex(i => i.outboundId == item.outboundId)
                if (index == -1) {
                    newArr.push(item)
                }
            })
            this.tableData = this.tableData.concat(newArr)
            //去重

            this.goodsVisible = false
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.form.pageSize = val
            this.selectGoods()

        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.form.page = val
            this.selectGoods()

        },
        getSummaries(param) {
            const { columns, data } = param;
            console.log(columns, data);

            const sums = [];
            sums[1] = '总计'
            sums[7] = data.reduce((pre, cur) => {
                const value = Number(cur.returnNum);
                if (!isNaN(value)) {
                    return pre + cur.returnNum * 1;
                } else {
                    return pre;
                }
            }, 0);
            const val = data.reduce((pre, cur) => {
                const value = Number(cur.returnMoney);
                if (!isNaN(value)) {
                    return pre + cur.returnMoney * 1;
                } else {
                    return pre;
                }
            }, 0);
            sums[8] = val.toFixed(2);
            return sums;
        },
        async remove(index) {
            this.tableData.splice(index, 1)
        },
        backList() {
            this.$router.go(-1)
        },
        async batchRemove() {
            if (this.multipleSelection.length === 0) return this.$message.warning('请选择要删除的商品')
            const ids = this.multipleSelection.map(item => item.outboundId)
            this.tableData = this.tableData.filter(item => !ids.includes(item.outboundId))
            // this.tableData = this.tableData.filter(item => item.id !== id)
        },
        openDetail(it) {
            window.open(it)
        },

        async suerClick(orderNumber) {
            const res = await this.$api.confirmReceipt({ orderNumber })
            if (res.status == 200) {
                this.$message.success('确认收货成功')
                this.getOrderDetail()
            }
        },
        exportOrder(orderNumber) {
            this.$api.orderExport({ orderNumber }).then((res) => {
                // if (res.status == 200) {
                //     // this.isLoading=false

                // }
            });
        },
        toggleSelection() {
            this.gridData.forEach(row => {
                this.tableData.forEach(item => {
                    if (item.outboundId == row.outboundId) {
                        this.$refs.multipleTable.toggleRowSelection(row, true);
                    }
                })
            })

        },


        async selectGoods() {
            const res = await this.$api.refundGoodsList(this.form)
            if (res.data.code == 200) {
                this.gridData = res.data.data.list
                this.total = res.data.data.total

            }
            this.$nextTick(() => {
                this.toggleSelection()
                this.goodsVisible = true
            })
        },
        // loadGoods() {

        // },
        resetSearch() {
            this.form = {
                id: +this.$route.query.id,
                keyword: "",
                batchCode: "",
                page: 1,
                pageSize: 10
            }
            this.selectGoods()
        },
        outOrderDetail(orderNumber) {
            this.$api.downloadOutOrder({ orderNumber }).then((res) => {
                // if (res.status == 200) {
                //     // this.isLoading=false

                // }
            });
            // window.location.href = item
        },
        async onChange(res, fileList) {
            console.log(fileList);
            this.fileList = fileList
            const fd = new FormData()
            fd.append('returnOrderFile', res.raw)
            const result = await this.$api.uploadCustomTicket(fd)
            console.log(result);
            this.ruleForm.returnOrderUrl = result.data.data.img
            this.$refs.ruleForm.validateField('returnOrderUrl')
        },
        onRemove(fileName, fileList, index) {
            this.ruleForm.returnOrderUrl = ''
            this.fileList = fileList
        },
        async onGoodsChange(res, fileList) {
            console.log(fileList);
            this.fileList1 = fileList
            const fd = new FormData()
            fd.append('returnGoods', res.raw)
            fd.append('id', +this.$route.query.id)
            const result = await this.$api.uploadGoods(fd)
            console.log(result);
            this.failNum = result.data.data.failNum
            this.exportTotal = result.data.data.total
            this.successNum = result.data.data.successNum
            this.upList = result.data.data.returnItem

        },
        onGoodsRemove() {
            this.fileList1 = []
            // this.ruleForm.returnOrderUrl = ''
        },
        refundUserExcel() {
            if (!this.fileList1.length) return this.$message.error('请上传文件')
            this.refundClose()
            this.exportVisible = true
            const newArr = []
            this.upList.forEach(item => {
                const index = this.tableData.findIndex(i => i.outboundId == item.outboundId)
                if (index == -1) {
                    newArr.push(item)
                }
            })
            this.tableData = this.tableData.concat(newArr)

            // this.
        },
        refundClose() {
            this.fileList1 = []
            this.refundVisible = false
        },
        percentClose() {
            this.exportVisible = false
        },
        knowIt() {
            this.exportVisible = false
        },
        downLoadTemplate() {
            window.open('https://b2b-img.hrjkjt.com/退货商品导入模板')
        },
        handleSelectionChange(val) {
            console.log(val);
            this.multipleSelection = val;
        },
        selectionChange(val) {
            this.selectionArr = val

        },
        goodsClose() {
            this.form = {
                id: +this.$route.query.id,
                keyword: "",
                batchCode: "",
                page: 1,
                pageSize: 10
            },
                this.goodsVisible = false

        },
    }
};
</script>
<style lang="scss" scoped>
.customer_search_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    justify-content: space-between;
    .search-item {
        margin-right: 12px;

        & > span {
            margin-right: 10px;
        }
    }
    .btn_box {
        margin-left: -9px;
        .btn_item {
            margin-left: 7px;
        }
    }
}
.link {
    :hover {
        color: #337cff;
    }
}
.back {
    :hover {
        color: #337cff;
    }
}
.downBtn {
    // width: 93px;
    padding: 0px 8px;
    height: 30px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #1a9dff;
    font-weight: 400;
    font-size: 14px;
    color: #1a9dff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    margin-left: 8px;
    :hover {
        color: #337cff;
        border-color: #337cff;
    }
}
.downBtn1 {
    // width: 93px;
    padding: 0px 8px;
    height: 30px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #1a9dff;
    font-weight: 400;
    font-size: 14px;
    color: #1a9dff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    margin-left: 8px;
    :hover {
        color: #ff4646;
        border-color: #ff4646;
    }
}
.downBtn2 {
    // width: 93px;
    text-align: center;
    line-height: 40px;
    padding: 0 0px;
    height: 40px;
    width: 110px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #1a9dff;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: #1a9dff;
    text-align: center;
    cursor: pointer;
    // margin-left: 8px;
}
.qiu {
    width: 10px;
    height: 10px;
    background: #1a9dff;
    border-radius: 50%;
}
.faren_box {
    margin-top: 14px;
    background: #fff;
    padding: 24px;
    .info_box {
        display: flex;
        align-items: center;
        // justify-content: flex-end;

        .info_item {
            color: #444141;
            font-size: 16px;
            // margin-left: 20px;

            span {
                font-weight: 600;
                color: #f92b2b;
            }

            &.shifu {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 22px;
                }

                .desc {
                    font-weight: 400;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
    }

    .btn_box {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        margin-top: 16px;

        .btn_item {
            width: 86px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            border-radius: 100px 100px 100px 100px;
            border: 1px solid #cccccc;
            font-size: 16px;
            color: #111111;
            cursor: pointer;

            &.active {
                border-color: #0687fe;
                color: #0687fe;

                &:hover {
                    border-color: #337cff;
                    color: #337cff;
                }
            }

            &:hover {
                border-color: #e3e3e3;
                color: #666666;
            }
        }
    }
}
.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px;
        }
        .el-dialog__header,
        .el-dialog__title {
            text-align: left;
            font-size: 20px;
            color: #1a1a1a;
            font-weight: 600;
        }
        .el-dialog__headerbtn {
            top: 24px;
        }
        .el-dialog--center .el-dialog__body {
            padding-top: 6px;
        }
    }
    .dialog_main {
        max-height: 500px;
        overflow-y: auto;
        padding-left: 20px;
        .delivery_item {
            font-weight: bold;
            font-size: 14px;
            color: #333333;

            &.active {
                background: #fafcfd;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #e6ebf5;
                margin-bottom: 9px;
            }

            .item_show {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 12px;

                span:last-of-type {
                    font-weight: 400;
                    font-size: 12px;
                    color: #337cff;
                    cursor: pointer;

                    &:hover {
                        color: #0687fe;
                    }
                }
            }

            .item_detail {
                &.active {
                    padding: 16px 20px;
                }

                .title {
                    font-size: 18px;
                    padding-bottom: 13px;
                    margin-bottom: 14px;
                    border-bottom: 1px solid #e6ebf5;
                }

                .btn {
                    font-size: 18px;
                    color: #337cff;
                    cursor: pointer;
                    text-align: center;

                    .icon {
                        font-weight: 600;
                        margin-left: 6px;
                    }

                    &:hover {
                        color: #0687fe;
                    }
                }
            }
        }
    }
}
.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px;
        }
        .el-dialog__header {
            text-align: left;
            font-size: 20px;
            color: #1a1a1a;
            font-weight: 600;
        }
        .el-dialog__headerbtn {
            top: 24px;
        }
        .el-dialog--center .el-dialog__body {
            padding-top: 6px;
        }
    }
    .dialog_main {
        .delivery_item {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #666666;
            margin-top: 7px;
            line-height: 22px;

            span:first-of-type {
                font-weight: bold;
                color: #333333;
            }
        }
    }
}
.order_detail {
    padding: 20px 24px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    margin-top: 16px;
    overflow: hidden;

    .top_number {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .order_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 12px;

        .order_number {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            color: #333333;

            .date {
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                margin-left: 22px;

                span {
                    color: #222222;
                }
            }
        }

        .status {
            font-weight: bold;
            font-size: 18px;
            color: #f7a400;
        }
    }

    .box_title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 16px;
            background: #1a9dff;
            border-radius: 100px 100px 100px 100px;
            margin-right: 7px;
        }
    }

    .receive_info {
        .info_box {
            padding: 14px 16px;
            background: #f7f7f7;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: flex-start;
            font-size: 12px;
            color: #666666;

            .info_item {
                width: 280px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 80px;

                .delivery_item {
                    display: flex;
                    flex-direction: column;

                    .more {
                        color: #1a9dff;
                        cursor: pointer;
                        margin-left: 10px;
                        &:hover {
                            color: #0687fe;
                        }
                        .icon {
                            font-weight: 600;
                        }
                    }
                }

                .pay_img {
                    margin-left: 16px;
                    color: #1a9dff;
                    cursor: pointer;
                    &:hover {
                        color: #0687fe;
                    }
                }

                .info_title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 11px;
                }

                span {
                    margin-bottom: 6px;
                }
            }
        }
    }

    .list_mode {
        .goods_list {
            border: 1px solid #eeeeee;

            .list_title {
                display: flex;
                align-items: center;
                height: 40px;
                border-bottom: 1px solid #eeeeee;
                font-size: 14px;

                span {
                    flex: 1;
                    text-align: center;

                    &:first-of-type {
                        flex: 3;
                        text-align: left;
                        text-indent: 71px;
                        margin-left: 16px;
                    }
                }
            }

            .list_item {
                position: relative;
                padding: 13px 0;
                border-bottom: 1px solid #eeeeee;
                background: #fff;
                display: flex;
                align-items: center;

                &:hover {
                    background: #f5fbff;
                }

                .goods_img {
                    margin-right: 18px;
                    img {
                        width: 130px;
                        height: 130px;
                    }
                }

                .goods_info {
                    .title {
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 13px;
                        .tag {
                            display: flex;
                            align-items: center;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                // width: 30px;
                                height: 17px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                font-size: 10px;
                                line-height: 17px;
                                margin-right: 4px;
                            }

                            .tj {
                                background: rgba(248, 213, 213, 0.9);
                                border: 1px solid #ffacac;
                                color: #f92b2b;
                            }
                            .ld {
                                background: #fef2f8;
                                padding: 2px 5px;
                                border: 1px solid #f8f2e7;
                                color: #ff2389;
                            }
                            .zy {
                                background: rgba(206, 231, 249, 0.9);
                                border: 1px solid #b7dcf8;
                                color: #1a9dff;
                            }
                            .yh {
                                width: auto;
                                padding: 0 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                color: #f7a400;
                            }
                        }
                    }
                    .info_box {
                        .info_item {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 5px;

                            & > div {
                                width: 320px;
                                margin-right: 16px;
                                display: flex;
                                align-items: center;
                            }

                            .info_name {
                                color: #999999;
                                margin-right: 12px;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        margin-top: 7px;

                        .price_box {
                            font-size: 20px;
                            color: #f34141;
                            font-weight: 600;
                        }

                        .yh_box {
                            position: relative;
                            margin-left: 10px;
                            padding-bottom: 2px;
                            .yh {
                                width: auto;
                                padding: 2px 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #f7a400;
                            }
                            .sj {
                                position: absolute;
                                left: -4px;
                                top: 8px;
                            }
                        }
                    }
                }

                .item_left {
                    flex: 3;
                    display: flex;
                    margin-left: 16px;
                }

                .item_right {
                    flex: 1;
                    text-align: center;
                    font-size: 18px;

                    &.price {
                        color: #f92b2b;
                    }
                    &.action {
                        font-size: 14px;
                        color: #1a9dff;
                        cursor: pointer;

                        &:hover {
                            color: #0687fe;
                        }
                    }
                }
            }
        }
    }

    .bottom_info {
        margin-top: 14px;
        .info_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .info_item {
                color: #444141;
                font-size: 16px;
                margin-left: 20px;

                span {
                    font-weight: 600;
                    color: #f92b2b;
                }

                &.shifu {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 22px;
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 16px;

            .btn_item {
                width: 86px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                border-radius: 100px 100px 100px 100px;
                border: 1px solid #cccccc;
                font-size: 16px;
                color: #111111;
                cursor: pointer;

                &.active {
                    border-color: #0687fe;
                    color: #0687fe;

                    &:hover {
                        border-color: #337cff;
                        color: #337cff;
                    }
                }

                &:hover {
                    border-color: #e3e3e3;
                    color: #666666;
                }
            }
        }
    }
}
.spesc {
    display: inline-block;
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.process_box {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    &.kh {
        .process_item {
            width: 238px;

            .item_title {
                background: url('~@/assets/images/order/bzs.png') no-repeat;
                background-size: 100% 100%;

                &.hui {
                    background: url('~@/assets/images/order/bzs_a1.png')
                        no-repeat;
                    background-size: 100% 100%;
                }
                &.active {
                    background: url('~@/assets/images/order/bzs_a.png')
                        no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .process_item {
        width: 294px;
        flex-shrink: 0;
        margin-left: -10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #666666;

        &:first-of-type {
            margin-left: 0;
        }

        .item_title {
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: url('~@/assets/images/order/bz.png');
            background-size: 100%;
            margin: 16px 0 6px;
            font-size: 16px;
            color: #333333;
        }
        .item_title.hui {
            color: #fff;
            background: url('~@/assets/images/order/bz_a1.png');
        }
        .item_title.active {
            color: #fff;
            background: url('~@/assets/images/order/bz_a.png');
        }
    }
}
</style>
<style lang="scss">
.el-table__footer-wrapper {
    font-weight: 700;
}
.el-upload-list {
    width: 300px !important;
}
.mydialog .el-dialog__header {
    border-bottom: 1px solid #e3e3e3;
}
.mydialog .el-dialog__title {
    font-weight: 700;
}
.el-step__icon.is-text {
    border-color: #1a9dff;
}
.el-step.is-vertical .el-step__line {
    background: #1a9dff;
}
</style>