<template>
    <!-- 我的发票 -->
    <div class="customer_box">
        <div class="customer_search_box">
            <div class="search-item">
                <span>关键字</span>
                <el-input style="width: 250px;" clearable placeholder="发票号/订单号/商品名称/商品编码/开票主体" size="small" v-model="filterinfo.keyword"></el-input>
            </div>
            <div class="search-item">
                <span>开票日期</span>
                <el-date-picker style="width: 200px;" v-model="timeRange" type="daterange" size="small" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div class="btn_box" style="display: flex;">
                <el-button class="btn_item" type="primary" size="small" @click="getInvoiceList(1)">查询</el-button>
                <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
                <!-- <el-button class="btn_item" type="primary" size="small" @click="batchDownLoad">批量下载</el-button> -->
                <div @click="batchDownLoad" v-loading.fullscreen.lock="fullscreenLoading" class="downBtn"><img src="../../assets/images/personal/downLoad.svg" alt=""> <span>批量下载</span></div>
            </div>
        </div>
        <div>
            <div class="alert" style=" font-size: 14px;"><img style="margin-right: 5px;;" src="../../assets/images/personal/warn.svg" alt=""> <span>已选择 <span style=" font-size: 18px;color: #f92b2b;">{{multipleSelection.length}}</span> 项</span><span style="margin-left: 10px;">总开票金额：<span style="font-size: 18px;color: #f92b2b;">{{voiceTotal.toFixed(2)}}</span></span></div>
        </div>
        <div class="table_mode">
            <el-table :data="tableData" v-loading="loading" stripe style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="invoiceNumber" label="发票号" min-width="110" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span style="font-size: 14px;color: #1A9DFF;cursor: pointer;" @click="viewInvoice(scope.row.invoiceUrl)">{{scope.row.invoiceNumber}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="order_number" label="订单号" min-width="130" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span style="font-size: 14px;color: #1A9DFF;cursor: pointer;" @click="viewOrder(scope.row.order_number)">{{scope.row.order_number}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="money" label="开票金额" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="invoiceDate" label="开票日期" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="statusTitle" label="状态" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="invoiceType" label="发票类型" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="invoiceCompanyName" label="开票主体" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="address" label="操作 " width="130" align="center">
                    <template slot-scope="scope">
                        <div class="table_btn">
                            <div v-loading.fullscreen.lock="fullscreenLoading" style="font-size: 14px;color: #1A9DFF;" class="btn_item" @click="dkxdClick(scope.row.id)">下载</div>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </div>
        <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getInvoiceList" @size-change="getInvoiceList(1)">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { handlerTableData, downloadFile } from '@/utils/common'
export default {
    name: 'MyInvoice',
    data() {
        return {
            loading: false,
            total: 0,
            fullscreenLoading: false,
            timeRange: [],
            multipleSelection: [],
            filterinfo: {
                keyword: "",
                invoiceDate: "",
                page: 1,
                pageSize: 10
            },
            tableData: [

            ],
        };
    },
    created() {

    },
    mounted() {
        this.getInvoiceList()
    },
    computed: {
        voiceTotal() {
            if (this.multipleSelection.length) {
                return this.multipleSelection.reduce((total, item) => {
                    return total + item.money;
                }, 0);
            } else {
                return 0;
            }
        }
    },
    methods: {
        handleSelectionChange(val) {
            console.log(val);
            this.multipleSelection = val;
        },
        viewInvoice(url) {
            window.open(url)
        },
        viewOrder(orderNumber) {
            // window.open('https://www.aminer.cn/order/orderDetail?orderNumber=' + orderNumber)
            window.open(`${this.baseURL}/hr/#/orderPersDetail?orderNumber=${orderNumber}`)
            // window.open(`http://pc.fastjz.com:9528/home/main#/orderPersDetail?orderNumber=${orderNumber}`)
        },
        async dkxdClick(id) {
            this.fullscreenLoading = true
            const res = await this.$api.invoiceListDownLoad({ ids: [id] })
            this.fullscreenLoading = false
            if (res.status == 200) {
                downloadFile(res)
            }
        },
        async batchDownLoad() {
         
            const ids = this.multipleSelection.map(item => item.id)
            if (!ids.length) {
                this.$message.warning('请选择要下载的发票')
                return
            }
            this.fullscreenLoading = true
            const res = await this.$api.invoiceListDownLoad({ ids })
            this.fullscreenLoading = false
            if (res.status == 200) {
                downloadFile(res)
            }
        },
        // 点击重置
        resetClick() {
            this.timeRange=[]
            this.filterinfo = {
                page: 1,
                pageSize: 10,
                keyword: '',
                invoiceDate: '',
            }
            this.getInvoiceList()
        },
        // 获取我的客户列表
        getInvoiceList(num) {
            if (num == 1) {
                this.filterinfo.page = 1
            }
            let params = this.filterinfo
            if (this.timeRange && this.timeRange.length) {
                params.invoiceDate = this.timeRange[0] + '/' + this.timeRange[1]
            } else {
                params.invoiceDate = ''
            }
            this.loading = true
            this.$api.invoiceList(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = res.data.data?handlerTableData(res.data.data.list):[]
                    // this.tableData = res.data.data.list
                    this.total = res.data.data?res.data.data.total:0
                }
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
    padding-top: 4px;
}
.alert {
    display: flex;
    align-items: center;
    // width: 600px;
    // margin-left: 20px;
    // justify-content: center;
    font-weight: bold;

    background: #e6f4ff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #b8e1ff;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 12px;
}
.downBtn{
    width: 93px;
height: 30px;
border-radius: 4px 4px 4px 4px;
border: 1px solid #1A9DFF;
font-weight: 400;
font-size: 14px;
color: #1A9DFF;
text-align: center;
line-height: 30px;
cursor: pointer;
margin-left: 8px;
}
</style>