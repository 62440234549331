<template>
    <div style="background:#F6F9FE;height:100%;padding-bottom:20px;">
        <div class="type_area" style="background:#FFF;padding:40px 110px;">
            <div>
                <div>
                    <div class="yhdRegister">
                        <div class="register">
                            <div class="register-form" style="width:600px;">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" label-position="left">
                                    <el-form-item label="法人多店退货" prop="isMain">
                                        <el-select v-model="ruleForm.isMain" placeholder="请选择" style="width: 100%;">
                                            <el-option label="是" :value="true"></el-option>
                                            <el-option label="否" :value="false"></el-option>
                                        </el-select>
                                        <div style="color:#999">
                                            <div>如果是单店退货，则只可以对单店订单发起退货；</div>
                                            <div>如果选择多店退货，则可以选择所有同法人多店下的订单发起退货</div>
                                        </div>
                                    </el-form-item>
                                    <!-- <el-form-item label="企业名称" prop="company_name" >
                                    <el-input v-model="ruleForm.company_name" placeholder="请填写营业执照上的名称"></el-input>
                                </el-form-item> -->
                                    <el-form-item label="客户名称" prop="companyName">
                                        <el-select v-model="ruleForm.companyName" placeholder="请选择客户名称" filterable remote :remote-method="remoteMethod" @change="changeCompany" style="width: 100%;">
                                            <el-option v-for="item in companyOptions" :key="item.memberId" :label="item.companyName" :value="item.companyName"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="所属省市区">
                                        <el-input v-model="ruleForm.region" :disabled="true" placeholder="请输入所属省市区"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系人">
                                        <el-input v-model="ruleForm.contacter" :disabled="true" placeholder="请输入联系人"></el-input>
                                    </el-form-item>
                                    <el-form-item label="发货日期" prop="outDate">
                                        <!-- <el-date-picker v-model="ruleForm.outDate" format="yyyy-MM-DD" value-format="yyyy-MM-DD" type="date" placeholder="选择日期">
                                        </el-date-picker> -->
                                        <el-date-picker v-model="ruleForm.outDate" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="发货金额（元）" prop="outMoney">
                                        <el-input v-model.number="ruleForm.outMoney" type="number" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="退货金额（元）" prop="returnMoney">
                                        <el-input v-model.number="ruleForm.returnMoney" type="number" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="原计划提报人 " prop="originalProposer">
                                        <el-input v-model="ruleForm.originalProposer" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="现客户负责人 " prop="currentManager">
                                        <el-input v-model="ruleForm.currentManager" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="退货原因 " prop="reason">
                                        <el-input type="textarea" :rows="4" v-model="ruleForm.reason" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="退货清单 " style="margin-bottom: 0;">
                                        <el-upload :limit="1" ref="upload_attach" action="" :auto-upload="false" :on-change="onChange" :file-list="fileList">
                                            <el-button size="small" type="primary">点击上传</el-button>
                                            <div slot="tip" class="el-upload__tip" style="margin-top: -10px;">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
                                            <div slot="file" slot-scope="{ file }">
                                                <li class="el-upload-list__item is-success">
                                                    <a @click="() => openUrl(file,fileList)" class="el-upload-list__item-name">
                                                        <i class="el-icon-document"></i>{{ file.name }}
                                                    </a>
                                                    <label class="el-upload-list__item-status-label">
                                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                                    </label>
                                                    <i @click.stop="$refs.upload_attach.handleRemove(file)" class="el-icon-close"></i>
                                                </li>
                                            </div>
                                        </el-upload>
                                    </el-form-item>
                                    <!-- <el-form-item prop="userAgree" label-width="200" class="user-item">
                                    <template slot="label">
                                       
                                    </template>
                                    
                                </el-form-item> -->
                                </el-form>
                            </div>
                            <div style="padding-left:140px;">
                                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                                <el-button @click="resetForm('ruleForm')">取消</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// import Steps from './component/step.vue'
export default {
    name: 'newRefund',

    data() {
        return {
            current: 1,
            // agreeVisible: false,
            agreeVisible: false,
            visible: false,
            fileList: [],
            companyOptions: [],
            time: 0,
            ruleForm: {
                companyName: '',
                memberId: '',
                region: '',
                contacter: '',
                outDate: '',
                outMoney: '',
                returnMoney: '',
                originalProposer: '',
                currentManager: '',
                reason: '',
                isMain: false,
                returnListUrl: ''
            },
            currentIndex: 0,
            agreeFlag: false,
            disabled: true,
            timer: null,
            agreeContent: '',
            rules: {
                companyName: [
                    { required: true, message: '请输入客户名称', trigger: 'change' },
                    // {
                    //     validator: validateCompanyName, trigger: 'blur'
                    // }
                ],
                outDate: [
                    { required: true, message: '请选择发货日期', trigger: 'change' },
                ],

                outMoney: [
                    { required: true, message: '请输入发货金额', trigger: 'blur' },
                ],
                returnMoney: [
                    { required: true, message: '请输入退款金额', trigger: 'blur' },

                ],
                originalProposer: [
                    { required: true, message: '请输入原计划提报人', trigger: 'blur' },
                ],
                currentManager: [
                    { required: true, message: '请现客户负责人', trigger: 'blur' },
                    // { validator: validatePass, trigger: 'blur' }
                ],
                reason: [
                    { required: true, message: '请输入退货原因', trigger: 'blur' },

                ],

                // userAgree:[
                // { required: true, validator: validatorType, trigger: 'change' }
                // ]
            },
        }
    },
    created() {
        this.getMyCustom()
    },
    methods: {
        async remoteMethod(val) {
            const res = await this.$api.getOwnCustom({
                search: val
            })
            if (res.data.code == 200) {
                this.companyOptions = res.data.data
            }
        },
        async getMyCustom() {
            const res = await this.$api.getOwnCustom({
                search: this.ruleForm.companyName
            })

            if (res.data.code == 200) {
                this.companyOptions = res.data.data
            }
            // console.log(this.$store.state.busyMemberInfo);
            this.ruleForm.originalProposer = this.$store.state.busyMemberInfo.contacter
            this.ruleForm.currentManager = this.$store.state.busyMemberInfo.contacter
        },
        handClose() {

        },
        openUrl(){
            window.open( this.ruleForm.returnListUrl)
        },
        changeCompany(val) {
            const custom = this.companyOptions.find(item => item.companyName == val)
            this.ruleForm.memberId = custom.memberId
            this.ruleForm.region = custom.businessDepart + custom.city + custom.area
            this.ruleForm.contacter = custom.contacter

        },
        async onChange(res, fileList) {
            console.log(fileList);
            const fd = new FormData()
            fd.append('returnListFile', res.raw)
            const result = await this.$api.uploadTicket(fd)
            console.log(result);

            this.ruleForm.returnListUrl = result.data.data.img
        },
        delImg(item) {
            item.list.pop()
        },
        submitForm(formName) {
            console.log(this.ruleForm, 'this.ruleForm');
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.startRefund(this.ruleForm)
                    if (res.data.code == 200) {
                        this.$message.success('发起退货成功')
                        this.$router.push('/customer/refundOrder')
                    } else {
                        this.$message.error(res.data.message)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.ruleForm.img = ''
            this.$router.push('/customer/refundOrder')
        },

    }
}
</script>
 
<style lang="scss" scoped>
.yhdRegister .register-form .verification {
    display: flex;
}
.imgList {
    position: relative;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.register-btn {
    background: #1a9dff;
    color: #fff;
    width: 398px;
    &:hover {
        background: #337cff;
    }
}
.next-btn {
    background: #fff;
    color: #333;
    width: 398px;
    &:hover {
        color: #1a9dff;
        border-color: #1a9dff;
    }
}

::v-deep .el-checkbox__input .el-checkbox__inner {
    border-radius: 50% !important;
}

.yhdRegister .register-form .verification-btn {
    position: absolute;
    cursor: pointer;
    right: 15px;
    color: #1a9dff;
    &:hover {
        color: #337cff;
    }
}
.yhdRegister {
    ::v-deep.el-form-item {
        display: flex;
        ::v-deep.el-input {
            width: 400px;
        }
    }
    ::v-deep.el-form-item__content {
        margin: 0 !important;
    }
    ::v-deep .el-form-item__label {
        text-align: right !important;
    }
}
.head {
    width: 1312px;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left {
    float: left;
}
.right {
    float: right;
}
.right a {
    color: #3cadfb !important;
}
.h56 {
    height: 40px;
    line-height: 40px;
}
.disabled-btn {
    pointer-events: none;
    font-size: 12px;
}
// body {
//     overflow-y: visible !important;

// }
// body::-webkit-scrollbar-thumb{
//     background: #fff !important;
// }
.user-agree {
    vertical-align: top;
    cursor: pointer;
    color: #1a9dff;
}
.user-item .el-form-item__label {
    position: relative;
    z-index: 999;
}
::v-deep.el-dialog__wrapper {
    overflow: visible !important;
}
.abow_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
}
.abow_dialog .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    overflow: hidden;
}
.abow_dialog .el-dialog .el-dialog__body {
    position: absolute;
    left: 0;
    top: 54px;
    bottom: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
}
.register-tit {
    background: #f7f8fa;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #f5f5f5;
    background: #f7f8fa;
    padding: 10px 20px;
    font-size: 16px;
    color: #333333;
    margin: 25px 0;
}
</style>