/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'
import { Loading } from 'element-ui'

let loadingInstance = null

Vue.use(Router)

const routes = [
  { path: '/login',
    component: resolve => (require(['../views/login/login.vue'], resolve)),
  },
  { path: '/register',
    component: resolve => (require(['../views/register/register.vue'], resolve)),
  },
  { path: '/forgetPwd',
    component: resolve => (require(['../views/forgetPwd/forgetPwd.vue'], resolve)),
  },
  {
    path: '/',
    redirect: '/index',
    component: resolve => (require(['../components/Home.vue'], resolve)),
    children: [
      {
        path: 'empty',
        name: 'Empty',
        component: resolve => (require(['../views/index/empty.vue'], resolve)),
      },
      {
        path: 'index',
        name: 'Index',
        component: resolve => (require(['../views/index/index.vue'], resolve)),
        meta: {keepAlive: true}
      },
      {
        path: 'goodsDetail',
        name: 'goodsDetail',
        component: resolve => (require(['../views/goodsDetail/goodsDetail.vue'], resolve)),
     
      },
      // 在线下单
      {
        path: 'onlineOrder',
        name: 'OnlineOrder',
        component: resolve => (require(['../views/order/onlineOrder.vue'], resolve)),
        meta: {keepAlive: true}
     
      },
      {
        path: 'shopCar',
        name: 'shopCar',
        component: resolve => (require(['../views/shopCar/shopCar.vue'], resolve))
     
      },
      {
        path: 'pay',
        name: 'pay',
        component: resolve => (require(['../views/pay/pay.vue'], resolve))
     
      },
      {
        path: 'orderInfo',
        name: 'orderInfo',
        component: resolve => (require(['../views/orderInfo/orderInfo.vue'], resolve))
     
      },
      //专题
      {
        path: 'specialSub',
        name: 'specialSub',
        component: resolve => (require(['../views/specialSub/specialSub.vue'], resolve))
     
      },
      //帮助中心
      {
        path: 'helpCenter',
        name: 'helpCenter',
        component: resolve => (require(['../views/helpCenter/helpCenter.vue'], resolve))
     
      },
      //公告
      {
        path: 'notice',
        name: 'notice',
        component: resolve => (require(['../views/notice/notice.vue'], resolve))
     
      },
      // 订单详情
      {
        path: 'orderDetail',
        name: 'OrderDetail',
        component: resolve => (require(['../views/order/orderDetail.vue'], resolve)),
     
      },
      // 个人中心-订单详情
      {
        path: 'orderPersDetail',
        name: 'OrderPersDetail',
        component: resolve => (require(['../views/order/orderPersDetail.vue'], resolve)),
     
      },
      // 退货单详情
      {
        path: 'refundDetail',
        name: 'refundDetail',
        component: resolve => (require(['../views/customer/components/refundDetail.vue'], resolve)),
     
      },
      // 个人中心-退货订单详情
      {
        path: 'orderReturnDetail',
        name: 'OrderReturnDetail',
        component: resolve => (require(['../views/order/orderReturnDetail.vue'], resolve)),
     
      },
      // 客户管理
      {
        path: 'customer',
        name: 'Customer',
        redirect: 'customer/customerIndex',
        component: resolve => (require(['../views/customer/Home.vue'], resolve)),
        children: [
          // 客户管理-首页
          {
            path: 'customerIndex',
            name: 'CustomerIndex',
            component: resolve => (require(['../views/customer/customerIndex.vue'], resolve)),
          },
          // 客户管理-我的客户
          {
            path: 'myCustomer',
            name: 'MyCustomer',
            component: resolve => (require(['../views/customer/myCustomer.vue'], resolve)),
          },
            // 客户管理-我的发票
            {
              path: 'myInvoice',
              name: 'MyInvoice',
              component: resolve => (require(['../views/customer/myInvoice.vue'], resolve)),
            },
            // 客户管理-退货
            {
              path: 'refundOrder',
              name: 'refundOrder',
              component: resolve => (require(['../views/customer/refundOrder.vue'], resolve)),
            },
            {
              path: 'refundOrder/newRefund',
              name: 'newRefund',
              component: resolve => (require(['../views/customer/components/newRefund.vue'], resolve)),
            },
          // 客户管理-客户订单
          {
            path: 'customerOrder',
            name: 'CustomerOrder',
            component: resolve => (require(['../views/customer/customerOrder.vue'], resolve)),
            meta: {keepAlive: true}
          },
          // 客户管理-重点产品销售进度
          {
            path: 'productSale',
            name: 'productSale',
            component: resolve => (require(['../views/customer/productSale.vue'], resolve)),
            meta: {keepAlive: true}
          },
          // 客户管理-客户账龄
          {
            path: 'customerAccounts',
            name: 'CustomerAccounts',
            component: resolve => (require(['../views/customer/customerAccounts.vue'], resolve)),
          },
          // 客户管理-客户账龄
          {
            path: 'customerAttribution',
            name: 'CustomerAttribution',
            component: resolve => (require(['../views/customer/customerAttribution.vue'], resolve)),
          },
        ]
      },
      // 发起调整客户归属
      {
        path: 'launchAttribution',
        name: 'LaunchAttribution',
        component: resolve => (require(['../views/customer/launchAttribution.vue'], resolve)),
      },
      // 调整客户归属详情
      {
        path: 'detailAttribution',
        name: 'DetailAttribution',
        component: resolve => (require(['../views/customer/detailAttribution.vue'], resolve)),
      },
      // 个人中心
      {
        path: 'personal',
        name: 'PersonalHome',
        redirect: 'personal/myOrder',
        component: resolve => (require(['../views/personal/Home.vue'], resolve)),
        children: [
          // 个人中心-我的订单
          {
            path: 'myOrder',
            name: 'MyOrder',
            component: resolve => (require(['../views/personal/myOrder.vue'], resolve)),
          },
          // 个人中心-待业务员审核
          {
            path: 'auditingOrder',
            name: 'AuditingOrder',
            component: resolve => (require(['../views/personal/auditingOrder.vue'], resolve)),
          },
          // 个人中心-我的发票
          {
            path: 'myInvoice',
            name: 'MyInvoice',
            component: resolve => (require(['../views/personal/myInvoice.vue'], resolve)),
          },
          // 个人中心-我的收藏
          {
            path: 'myFavorites',
            name: 'MyFavorites',
            component: resolve => (require(['../views/personal/myFavorites.vue'], resolve)),
          },
          // 个人中心-我的优惠券
          {
            path: 'myCoupon',
            name: 'MyCoupon',
            component: resolve => (require(['../views/personal/myCoupon.vue'], resolve)),
          },
          // 个人中心-采购历史
          {
            path: 'purchaseHistory',
            name: 'PurchaseHistory',
            component: resolve => (require(['../views/personal/purchaseHistory.vue'], resolve)),
          },
          // 个人中心-我的留言
          {
            path: 'myMessage',
            name: 'MyMessage',
            component: resolve => (require(['../views/personal/myMessage.vue'], resolve)),
          },
          // 个人中心-收货地址
          {
            path: 'myAddress',
            name: 'MyAddress',
            component: resolve => (require(['../views/personal/myAddress.vue'], resolve)),
          },
          // 个人中心-账号信息
          {
            path: 'accountInfo',
            name: 'AccountInfo',
            component: resolve => (require(['../views/personal/accountInfo.vue'], resolve)),
          },
          // 个人中心-付款委托书
          {
            path: 'payBook',
            name: 'payBook',
            component: resolve => (require(['../views/personal/payBook.vue'], resolve)),
          },
          // 个人中心-修改密码
          {
            path: 'changePassword',
            name: 'ChangePassword',
            component: resolve => (require(['../views/personal/changePassword.vue'], resolve)),
          },
        ]
      },
    ]
  },
]

const router = new Router({
  base: '/hr',
  mode: 'hash',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 页面滚动条配置
    if(savedPosition) {
        return savedPosition
    } else {
        return {
            x: 0,
            y: 0
        }
    }
  }
})

router.beforeEach((to, from, next) => {
  // 如果你有全局状态或资源需要重置，在这里进行重置操作
  // 例如，重置一个全局状态对象
  if (to.path !== from.path) {
    // 重置页面滚动位置
    // window.scroll(0, 0); 
 
    // 或者销毁需要销毁的实例
    // 例如，如果你有一个需要清理的全局服务实例，可以调用其destroy方法
    // globalService.destroy();
  }
  // 页面跳转增加loading
  loadingInstance = Loading.service({ fullscrenn: true})
  // 必须调用next()来resolve这个钩子
  next();
});

router.afterEach(()=>{
  loadingInstance.close()
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
