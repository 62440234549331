<template>
  <div class="type_area customer_home">
    <!-- 公共侧边栏 -->
    <div class="sidebar_box">
      <div class="sidebar_item" :class="{active: currentUrl == item.url}" @click="pageJump(item.url,item.name)" v-for="item in sidebarList" :key="item.name">{{item.name}}</div>
    </div>

    <!-- 主要区域容器 -->
    <div class="customer_main">
      <div class="bread_box">
        <span>客户管理 / {{currentName}}</span>
      </div>
      <div>
        <router-view></router-view>
      </div>
    </div>
    <!-- 主要区域容器END -->
  </div>
</template>

<script>

export default {
  name: 'CustomerHome',
  data() {
    return {
      currentUrl: this.$route.path,
      currentName: '',
      sidebarList: [{
        name:'主页',
        url: '/customer/customerIndex'
      },{
        name:'我的客户',
        url: '/customer/myCustomer'
      },
      {
        name:'客户订单',
        url: '/customer/customerOrder'
      },
      {
        name:'重点产品销售进度',
        url: '/customer/productSale'
      },
      {
        name:'客户账龄',
        url: '/customer/customerAccounts'
      },
      {
        name:'发票列表',
        url: '/customer/myInvoice'
      },
      {
        name:'退货单',
        url: '/customer/refundOrder'
      },
    
      ],
    };
  },
  watch: {
    // 监听路由对象的变化
    '$route'(to, from) {
      // 当路由发生变化时，你可以在这里处理你的逻辑
      this.currentUrl = this.$route.path
      this.sidebarList.forEach(item=>{
        if(item.url == this.currentUrl) this.currentName = item.name
      })
      // 你可以在这里根据路由做出相应的处理
    }
  },
  mounted() {
    this.currentUrl = this.$route.path
    this.sidebarList.forEach(item=>{
      if(item.url == this.currentUrl) this.currentName = item.name
    })
  },
  methods: {
    // 页面跳转
    pageJump(url,name){
      this.currentName = name
      this.currentUrl = url
      this.$router.push(url)
    },
  }
};
</script>
<style lang="scss" scoped>
.customer_home{
  display: flex;
  align-items: flex-start;
  margin-top: 18px;

  .sidebar_box{
    flex-shrink: 0;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 20px;

    .sidebar_item{
      width: 160px;
      box-sizing: border-box;
      padding: 12px 16px;
      background: #fff;
      font-size: 16px;
      color: #333333;
      cursor: pointer;

      &.active{
        background: #E7F5FF;
        color: #1A9DFF;
      }

      &:hover{
        color: #1A9DFF;
      }
    }
  }

  .customer_main{
    width: calc(100% - 180px);
    flex: 1;

    .bread_box{
      margin-bottom: 12px;
      font-size: 16px;
      color: #666666;
      padding-top: 9px;
    }
  }
}
</style>