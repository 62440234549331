/**
 * 处理表格数组空数据为‘--’
 */
import { Message } from 'element-ui';
 export function handlerTableData(arr){
	arr.forEach((item) => {
			for (let key in item) {
			if (item[key] === "" || item[key] === null || item[key] === undefined) {
					item[key] = "--";
			}
			}
	});
	return arr;
};
/**
 * 文件下载
 * @param {*} res 返回的文件流
 * @param {*} obj 其他可变参数
 */
export function downloadFile (res, obj) {
	console.log(decodeURI(res.headers['content-length']))
	// 下载处理
	console.log(res,'ress');
	var blob = new Blob([res.data],{
		type: "application/x-zip-compressed",
	});
	console.log(blob,'blob');
	if (res.data.type.indexOf("application/json") > -1) {
	  var fileReader = new FileReader();
	  fileReader.readAsText(blob, "utf-8");
	  fileReader.onload = function () {
		var tipResult = JSON.parse(fileReader.result);
		console.log(tipResult);
		Message.warning(tipResult.msg);
	  };
	} else {
	  blob = new Blob([res.data], {
		type: "application/x-zip-compressed",
	  });
	  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(
		  blob,
		  res.headers["content-disposition"]
		);
	  } else {
		const downloadElement = document.createElement("a"); // 生成文件路径
		downloadElement.href = window.URL.createObjectURL(blob); // 获取不带后缀名的文件名
		// 文件名中有中文 则对文件名进行转码
		downloadElement.download = decodeURI(res.headers["content-length"]);
		downloadElement.download =   decodeURI(res.headers["content-length"])+ '.zip';
		// 利用a标签做下载
		document.body.appendChild(downloadElement);
		downloadElement.click();
		window.URL.revokeObjectURL(downloadElement);
	  }
	}
  };
export function downloadExcelFile (res, obj) {
	console.log(decodeURI(res.headers['content-length']))
	// 下载处理
	console.log(res,'ress');
	var blob = new Blob([res.data],{
		type: "application/x-zip-compressed",
	});
	console.log(blob,'blob');
	if (res.data.type.indexOf("application/json") > -1) {
	  var fileReader = new FileReader();
	  fileReader.readAsText(blob, "utf-8");
	  fileReader.onload = function () {
		var tipResult = JSON.parse(fileReader.result);
		console.log(tipResult);
		Message.warning(tipResult.msg);
	  };
	} else {
	  blob = new Blob([res.data], {
		type: "application/x-zip-compressed",
	  });
	  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(
		  blob,
		  res.headers["content-disposition"]
		);
	  } else {
		const downloadElement = document.createElement("a"); // 生成文件路径
		downloadElement.href = window.URL.createObjectURL(blob); // 获取不带后缀名的文件名
		// 文件名中有中文 则对文件名进行转码
		downloadElement.download = decodeURI(res.headers["content-length"]);
		downloadElement.download =   '华人商品列表'+ '.xlsx';
		// 利用a标签做下载
		document.body.appendChild(downloadElement);
		downloadElement.click();
		window.URL.revokeObjectURL(downloadElement);
	  }
	}
  };
  export function exportResponseData(data, contentType, fileName) {
	const downloadLink = window.document.createElement('a')
	downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: contentType }))
	downloadLink.download = fileName
	document.body.appendChild(downloadLink)
	downloadLink.click()
	document.body.removeChild(downloadLink)
}
